import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from './../_services/api.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from './../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-detalleproducto',
  templateUrl: './detalleproducto.component.html',
  styleUrls: ['./detalleproducto.component.css']
})
export class DetalleproductoComponent implements OnInit {
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['<', '>'],
    responsive: {
      0: {
        items: 5
      },
      400: {
        items: 5
      },
      740: {
        items: 5
      },
      940: {
        items: 5
      }
    },
    nav: true
  }
  anuncio: any = [];
  loading: boolean = false;
  sinResultados: boolean = false;
  textoBusqueda: string = '';
  mensajeForm: FormGroup;
  idUsuarioActual: string = localStorage.getItem('idUsuario');
  imagenActual: string;

  constructor(private api: ApiService, private activatedRoute: ActivatedRoute, private toastr: ToastrService) {
    this.mensajeForm = new FormGroup({
      mensaje: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.cargarAnuncio(params['id']);
      this.actualziarContador(params['id']);
    });


  }

  actualziarContador(id){
    this.api.putResponse('actualizarVistas/' + id, false).pipe(first())
    .subscribe(
      data => {

      }, err => {
      });
  }

  async cargarAnuncio(id) {
    this.api.getResponse('anuncio/' + id, false).pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          this.anuncio = data[0];
          this.imagenActual = this.anuncio.imagenes[0];
          this.cargarAnunciosRelacionados(this.anuncio.categoriaId._id);
        }, err => {
        });
  }

  enviar(anuncio) {
    let params = {
      usuarioId: localStorage.getItem('idUsuario'),
      vendedorId: anuncio.usuarioId._id,
      anuncioId: anuncio._id,
      mensaje: this.mensajeForm.controls['mensaje'].value
    }

    this.api.postResponse('enviarmensaje/' + localStorage.getItem('idUsuario'), true, params).pipe(first())
      .subscribe(
        data => {
          let id = '';
          if (params.usuarioId == localStorage.getItem('idUsuario')) {
            id = params.vendedorId
          }
          else {
            id = params.usuarioId;
          }
          let param = {
            idUsuario: params.vendedorId,
            mensaje: this.mensajeForm.controls['mensaje'].value
          }
          this.api.postResponse('notificacion', true, param).pipe(first())
            .subscribe(
              data => {

                this.mensajeForm.controls['mensaje'].setValue('');
              }, err => {
              });
          this.mensajeForm.controls['mensaje'].setValue('');
          Swal.fire('LatinosVentas', 'Se ha enviado tu mensaje', 'success');
          this.mensajeForm.reset();
        }, err => {
        });
  }

  VerImagen(img) {
    this.imagenActual = img;
  }

  anunciosRealacionados: any = [];
  async cargarAnunciosRelacionados(catId) {

    this.api.getResponse('anuncioCategoria/' + catId , true).pipe(first())
      .subscribe(
        data => {
          this.anunciosRealacionados = data;
        }, err => {
        });
  }

  agregarFavoritos(item) {
    let usuario = {
      usuarioId: localStorage.getItem('idUsuario'),
      anuncioId: item._id
    };


    if (item.tieneFavorito) {
      this.api.deleteResponse('favoritos/' + item._id, true).pipe(first())
        .subscribe(
          data => {
            item.tieneFavorito = !item.tieneFavorito;
            this.toastr.success('LatinosVentas', 'Se ha quitado de tus favoritos');
          }, err => {
          });
    }
    else {
      this.api.postResponse('favoritos', true, usuario).pipe(first())
        .subscribe(
          data => {
            item.tieneFavorito = !item.tieneFavorito;
            this.toastr.success('LatinosVentas', 'Se ha agregado a tus favoritos');
          }, error => {

          })
    }
  }


  ObtenerUsername(nom) {
    
    return nom.substr(0, nom.indexOf('@'))
  }
}
