<header class="header-section">
  <div class="header-top">
    <div class="container">
      
      <div class="ht-left" style="padding: 8px;color: #202124;font-size: 19px;">
        Artículos de segunda mano y Directorio Latino de negocios en Canadá
      </div>
      <div class="ht-right">


        <a style=" margin-right: 17px;font-weight: bold;" class="login-panel">Intercambia, compra y vende</a>
        <div class="top-social">
          <a href="https://www.facebook.com/LatinosVentascom-106169285025964" target="_blank"><i
              class="ti-facebook"></i></a>
          <a href="https://twitter.com/latinosventas" target="_blank"><i class="ti-twitter-alt"></i></a>
          <a href="https://www.instagram.com/latinosventas/" target="_blank"><i class="ti-instagram"></i></a>
        </div>

      </div>
    </div>
  </div>


</header>

<nav class="navbar sticky-top navbar-expand-lg navbar navbar-dark bg-dark">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">
      <img src="/assets/img/logosinslogan.png" alt="" style="width: 100px;">
    </a>
    <button *ngIf="!this.isMobile" class="navbar-toggler" type="button" data-toggle="collapse"
      data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div *ngIf="!this.isMobile" class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link" aria-disabled="true" routerLink="">Inicio</a>
        </li>

        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            Categorías
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" style="overflow-y: scroll;height: 400px;">
            <!-- <a class="dropdown-item" (click)="irCategoria(item._id)" *ngFor="let item of this.categorias"
              style="cursor: pointer;">{{item.nombre}}</a> -->
            <a class="dropdown-item" routerLink="{{abrirProductos(item._id)}}" *ngFor="let item of this.categorias">
              {{item.nombre}}
            </a>
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/directorio" tabindex="-1" aria-disabled="true"  routerLinkActive="active">Directorio Latino</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/soporte" tabindex="-1" aria-disabled="true"  routerLinkActive="active">Soporte</a>
        </li>
      </ul>

      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <div class="advanced-search">
            <form [formGroup]="busquedaForm" (ngSubmit)="this.buscar()">
              <div class="input-group">
                <input type="text" style="padding: 19px;height: 42px;color: #020202; width: 270px;"
                  formControlName="query" placeholder="Qué estás buscando?">
                <button class="btn-search" type="submit"><i class="ti-search"></i></button>
              </div>
            </form>
          </div>
        </li>
        <li class="nav-item">
          <a (click)="register()" style="cursor:pointer;padding: 8px 8px 0px 50px;"
            class="login-panel nav-link nav-item" *ngIf="!this.isLoggedIn">Crear cuenta</a>
          <a (click)="login()" style="cursor:pointer;    margin-right: 17px;" class="login-panel nav-link nav-item"
            *ngIf="!this.isLoggedIn">Iniciar sesión</a>
        </li>

        <li class="nav-item ">
          <a routerLink="Perfil/Mensajes" style="padding:8px;    margin-left: 13px;" class="login-panel"
            *ngIf="this.isLoggedIn">
            <span class="badge badge-danger">{{this.noLeidas}}</span>
            <i style="color:white;" class="ti-bell bell-icon"></i>
          </a>
        </li>
        <li class="nav-item" style="width: 133px;">
          <a style="cursor:pointer;padding:8px;" class="login-panel" *ngIf="this.isLoggedIn">
            <button type="button" (click)="publicar()" class="btn-post">Publica gratis!</button>
          </a>
        </li>
        <li class="nav-item" data-toggle="tooltip" data-placement="top" title="{{getCorreo()}}">
          <a (click)="abrirPerfil()" style="cursor:pointer;margin-right: 17px;color: #8c8c76;padding:8px;"
            *ngIf="this.isLoggedIn" >Mi cuenta</a>
        </li>

      </ul>
    </div>
  </div>
</nav>

<script>
  $('.navbar-collapse a').click(function () {
    $(".navbar-collapse").collapse('hide');
  });
</script>


<header class="header-section" [hidden]="true" *ngIf="!this.isMobile">
  <div class="header-top">
    <div class="container">
      <div class="ht-left" style="padding: 13px;     color: #a8a9ad;">
      </div>
      <div class="ht-right">

        <a (click)="register()" style="cursor:pointer;" class="login-panel" *ngIf="!this.isLoggedIn">Crear cuenta</a>
        <a (click)="login()" style="cursor:pointer;    margin-right: 17px;" class="login-panel"
          *ngIf="!this.isLoggedIn">Iniciar sesión</a>

        <a style="cursor:pointer;" class="login-panel" *ngIf="this.isLoggedIn">
          <button type="button" (click)="publicar()" class="btn-post">Publica gratis!</button>
        </a>
        <a routerLink="Perfil/Mensajes" class="login-panel" *ngIf="this.isLoggedIn">
          <span class="badge badge-danger">{{this.noLeidas}}</span>
          <i class="ti-bell bell-icon"></i>
        </a>
        <a (click)="abrirPerfil()" style="cursor:pointer;    margin-right: 17px;" class="login-panel"
          *ngIf="this.isLoggedIn">Mi cuenta( {{getCorreo()}}) </a>
        <div class="top-social">
          <!-- <a href="#"><i class="ti-facebook"></i></a> -->
          <a href="https://twitter.com/latinosventas"><i class="ti-twitter-alt"></i></a>
          <a href="https://www.instagram.com/latinosventas/"><i class="ti-instagram"></i></a>
          <!-- <a href="#"><i class="ti-pinterest"></i></a> -->
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="inner-header">
      <div class="row">
        <div class="col-lg-4 col-md-4">
          <div class="logo">
            <a routerLink="">
              <img src="/assets/img/logosinslogan.png" alt="" style="width: 150px;">
            </a>
          </div>
        </div>
        <div class="col-lg-7 col-md-7" style="padding-top: 25px;">
          <div class="advanced-search">
            <form [formGroup]="busquedaForm" (ngSubmit)="this.buscar()">
              <div class="input-group">
                <input type="text" style="    height: 48px;color: #020202;" formControlName="query"
                  placeholder="Qué estás buscando?">
                <button type="submit"><i class="ti-search"></i></button>
              </div>
            </form>
            <p class="h3" style="padding: 15px 0px 0px 41px;">Intercambia, compra y vende</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="nav-item">
    <div class="container">

      <nav class="nav-menu mobile-menu">
        <ul>
          <li class="active"><a routerLink="/">Inicio</a></li>

          <li><a>Categorías</a>
            <ul class="dropdown">
              <li *ngFor="let item of this.categorias">
                <a (click)="irCategoria(item._id)" *ngIf="item.activa" style="cursor: pointer;">{{item.nombre}}</a>
              </li>
            </ul>
          </li>

          <li><a routerLink="/directorio">Directorio Latino</a></li>
          <li><a routerLink="/soporte">Soporte</a></li>
        </ul>
      </nav>
      <div id="mobile-menu-wrap"></div>
    </div>
  </div>
</header>

<div class="container" *ngIf="this.isMobile" style="min-height: 100vh; background-color:#00b0b9; color:white;">
  <div class="row text-center">
    <h2 style="color: white;margin-top: 30px;">Obtén una mejor experiencia con nuestra app</h2>
    <p>
      <a href="https://play.google.com/store/apps/details?id=com.latinos.ventas" class="btn btn-app-store">
        <img src="../assets/img/pstore.png" alt="" style="width: 100%;">
      </a>
      <a href="https://apps.apple.com/ca/app/latinos-ventas/id1572322739" class="btn btn-app-store">
        <img src="../assets/img/appstore.png" alt="" style="width: 100%;padding: 27px;">
      </a>
    </p>
  </div>
</div>

<div class="my-router-outlet" *ngIf="!this.isMobile">
  <router-outlet>
    <div style="background-color: gray">
    </div>
  </router-outlet>
</div>
<!-- Footer Section Begin -->
<footer class="footer-section" *ngIf="!this.isMobile">

  <div class="container">
    <div class="row">
      <div class="col-lg-2">
        <div class="footer-left">
          <div class="footer-logo">
            <img src="/assets/img/logosinslogan.png" alt="" style="width: 100px;">
          </div>
        </div>
      </div>
      <div class="col-lg-3 offset-lg-1">
        <div class="footer-widget">
          <h5>Información</h5>
          <ul>
            <li><a routerLink="/soporte">Soporte</a></li>
            <li><a routerLink="/privacidad">Aviso de privacidad</a></li>
            <li><a routerLink="/terminos">Términos y condiciones</a></li>
          </ul>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="footer-widget">
          <h5>Mi cuenta</h5>
          <ul>
            <li><a routerLink="Perfil/MisAnuncios">Publicaciones</a></li>
            <li><a routerLink="Perfil/Mensajes">Mensajes</a></li>
            <li><a routerLink="Perfil/Favoritos">Favoritos</a></li>
          </ul>
        </div>
      </div>
      <div class="col-lg-3">
        <a href="https://play.google.com/store/apps/details?id=com.latinos.ventas" target="_blank">
          <img src="../assets/img/pstore.png" class="img-fluid" width="70%">
        </a>
        <a href="https://apps.apple.com/ca/app/latinos-ventas/id1572322739" target="_blank">
          <img src="../assets/img/appstore.png" class="img-fluid" style="width: 63%;margin-left: 10px;">
        </a>
      </div>
    </div>
  </div>



  <div class="copyright-reserved">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="copyright-text">
            Copyright &copy;
            <script>
              document.write(new Date().getFullYear());
            </script> Derechos reservados <a href="https://colorlib.com" target="_blank"> latinosventas.com</a>
          </div>
        </div>
      </div>
    </div>
  </div>

</footer>