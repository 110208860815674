<div class="container" style="padding: 30px;">
  <div class="row" style="margin-top: 60px;margin-bottom: 60px;">
    <div class="col-lg-12">
      <p class="h1 text-center">
        ¿En qué podemos ayudarte?
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <!-- <img src="../../assets/img/support.jpg" style="width: 100%;"> -->
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6" style="padding: 45px;">
      <ngb-accordion #myaccordion="ngbAccordion" activeIds="panelOne" closeOthers="true" type="'success'"
        (panelChange)="beforeChange($event)">

        <ngb-panel title="¿Qué es LatinosVentas?" id="panelOne">
          <ng-template ngbPanelContent>
            LatinosVentas es una comunidad donde puedes anunciar de manera gratuita los articulos que quieres vender y
            que los demás pueden aprovechar
          </ng-template>
        </ngb-panel>
        <ngb-panel title="¿Cómo funciona LatinosVentas?" id="panelTwo">
          <ng-template ngbPanelContent>
            Podrás explorar los anuncios publicados en la plataforma sin necesidad de una cuenta, y deberás crear una
            cuenta para contactar a los vendedores o publicar tus propios anuncios
          </ng-template>
        </ngb-panel>
        <ngb-panel title="¿Cómo me registro?" id="panelThree">
          <ng-template ngbPanelContent>
            En la sección Crear Cuenta encontraras el link para registrarte mediante redes sociales o un correo
            electrónico
          </ng-template>
        </ngb-panel>
        <ngb-panel title="Cuánto cuesta?" id="panelFive">
          <ng-template ngbPanelContent>
            El registro es totalmente gratuito
          </ng-template>
        </ngb-panel>
        <ngb-panel title="Cómo publico un anuncio?" id="panelSix">
          <ng-template ngbPanelContent>
            Una vez creada tu cuenta ve a la sección Publicar, en donde te guiaremos paso a paso para que publiques tu
            anuncio
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
    <div class="col-lg-6">
      <img src="../../assets/img/support_main.jpg" style="width: 70%;">
    </div>
  </div>
  <hr>
  <div class="row" style="margin-top: 60px;margin-bottom: 60px;">
    <div class="col-lg-4">
      <!-- <div class="card-support">
        <div class="row">
          <div class="col-lg-3"><i class="ti-shopping-cart card-icon"></i></div>
          <div class="col-lg-9">Compra y vende seguro</div>
          <br>
          <p class="text-center">Conoce nuestro proceso de compra y en que podemos facilitarlo</p>
        </div>
      </div> -->
    </div>
    <div class="col-lg-4">
      <!-- <div class="card-support">
        <div class="row">
          <div class="col-lg-3"><i class="ti-ticket card-icon"></i></div>
          <div class="col-lg-9">Anúnciate</div>
          <br>
          <p class="text-center">Quieres que tu negocio aparezca en Latinosventas.com?</p>
        </div>

      </div> -->
      <div class="card-support">
        <div class="row">
          <div class="col-lg-3"><i class="ti-headphone card-icon"></i></div>
          <div class="col-lg-9">¿Tienes dudas?</div>
          <br>
          <p class="text-center">Escríbenos al correo</p>
          <a href="mailto:soporte@latinosventas.com">soporte@latinosventas.com</a>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <!-- <div class="card-support">
        <div class="row">
          <div class="col-lg-3"><i class="ti-headphone card-icon"></i></div>
          <div class="col-lg-9">Contacto</div>
          <br>
          <p class="text-center">No encontraste una respuesta, contácta a un asesor</p>
        </div>
      </div> -->
    </div>
  </div>
  <hr>
  <img src="../../assets/img/support.jpg" style="width: 100%;">
</div>
