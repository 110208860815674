<div class="container">

    <div class="sub-list">
        <div class="row" style="padding: 50px 30px 50px 12px;">
            <!-- <div class="col-lg-3">
                <div style="margin-top: 5px;">
                    <select #provincia class="custom-select form-control" placeholder="Provincia"
                        (change)='seleccionarProvincia(provincia.value)'>
                        <option value="0">Selecciona una provincia</option>
                        <option value="{{item._id}}" *ngFor="let item of provincias">{{item.nombre}}</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-3">
                <div style="margin-top: 5px;">
                    <select #ciudad class="custom-select form-control" placeholder="Ciudad"
                        (change)='seleccionarCiudad(ciudad.value)'>
                        <option value="0">Selecciona una ciudad</option>
                        <option value="{{item._id}}" *ngFor="let item of ciudades">{{item.nombre}}</option>
                    </select>

                </div>
            </div> -->
            <div class="col-lg-6">
                <div style="margin-top: 5px;">
                    <input #ciudad class="custom-select form-control" ngx-google-places-autocomplete
                        [options]="{ types: ['(cities)'], componentRestrictions: { country: 'CA' }}"
                        #placesRef="ngx-places" placeholder="Escribe la ubicación para negocios"
                        (onAddressChange)="handleAddressChange($event)" />
                </div>
            </div>
            <div class="col-lg-3">
                <div style="margin-top: 5px;">
                    <select #catId class="form-control" placeholder="Categoría Id"
                        (change)='filtrarCat(ciudad.value,catId.value )'>
                        <option value="0">Selecciona una categoría</option>
                        <option value="{{item._id}}" *ngFor="let item of categoriasNegocio">{{item.nombre}}</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-3">
                <a style="cursor:pointer;padding:8px;" class="login-panel" routerLink="/nuevonegocio">
                    <button type="button" class="btn-post">¡Anúnciate Gratis!</button>
                </a>
            </div>
        </div>
        <div class="row" style="margin-bottom: 100px;">
            <div *ngIf="negocios.length <= 0" class="center-div text-center">
                <img src="../../assets/img/notfound.png" alt="">
                <div class="h1">No encontramos resultados</div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 " *ngFor="let item of negocios">
                <div class="product-slider shadow-box" >
                    <div class="row">
                        <div class="col-lg-4  col-md-4 col-sm-4 col-xs-12">
                            <div class="pi-pic" style="background-color: #f9f9f9;cursor: pointer;">
                                <img [src]="item.logo" alt="" class="img-anuncio img-fluid">

                                <ul>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-8  col-md-8 col-sm-8 col-xs-12" style="padding: 10px 25px 5px 5px;">
                            <div class="product-item">
                                <div>
                                    <span
                                        style="font-size: 20px; text-decoration: none; color:#e7ab3c;">{{item.nombre}}</span>
                                    <p style="font-size: 15px; text-decoration: none; color:#292929 ;">
                                        {{item.direccion}} - TEL: {{item.telefono}}</p>
                                    <p
                                        style="font-size: 13px; text-decoration: none; color:#282828;word-wrap: break-word;line-height: normal;">
                                        {{item.descripcion | slice:0:180}}<span *ngIf="item.descripcion.length > 180" style="font-size: 15px; text-decoration: none; color:#6d6d6d">...</span> </p>
                                        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>