import { ApiService } from './../../_services/api.service';
import { first } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-favoritos',
  templateUrl: './favoritos.component.html',
  styleUrls: ['./favoritos.component.css']
})
export class FavoritosComponent implements OnInit {

  anuncios: any = [];
  isLoading: Boolean = false;

  constructor(private api: ApiService, private activatedRoute: ActivatedRoute, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.cargarFavoritos();
  }

  cargarFavoritos() {
    this.isLoading = !this.isLoading;
    this.spinner.show();
    this.api.getResponse('favoritos/' + localStorage.getItem('idUsuario'), true).pipe(first())
      .subscribe(
        data => {
          console.log('favoritos', data);
          this.anuncios = data.filter(x => x.anuncioId);
          this.spinner.hide();
          this.isLoading = !this.isLoading;
        }, err => {
          this.spinner.hide();
          this.isLoading = !this.isLoading;
        });
  }

  agregarFavoritos(item) {
    let usuario = {
      usuarioId: localStorage.getItem('idUsuario'),
      anuncioId: item._id
    };

    console.log(usuario, item);
    this.api.deleteResponse('favoritos/' + item.anuncioId._id, true).pipe(first())
      .subscribe(
        data => {
          item.tieneFavorito = !item.tieneFavorito;
          Swal.fire('LatinosVentas', 'Se ha quitado a tus favoritos', 'success');
          this.cargarFavoritos();
        }, err => {
        });
  }

}
