<div class="container" style="margin-top: 50px;margin-bottom: 100px;">

    <div class="row">
        <div class="col">
            <span style="font-size: 30px; text-decoration: none; color:#e7ab3c;">Llena tu solicitud</span>
            <hr>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 ">
            <form [formGroup]="registerForm">
                <div class="form-group">
                    <select #categoria class="custom-select form-control" formControlName="categoriaNegocioId"
                        placeholder="Categoría">
                        <option value="0">Selecciona una categoría</option>
                        <option value="{{item._id}}" *ngFor="let item of categoriasNegocio">{{item.nombre}}</option>
                    </select>
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" formControlName="nombre" placeholder="Nombre del negocio"
                        required>
                    <div *ngIf="registerForm.get('nombre').invalid  && (registerForm.get('nombre').dirty )">
                        <small class="text-danger" *ngIf="registerForm.get('nombre').errors.required">
                            Ingresa el nombre del negocio
                        </small>
                        <small class="text-danger" *ngIf="registerForm.get('nombre').hasError('maxlength')">
                            Ingresa un máximo de 90 caracteres
                        </small>
                    </div>
                </div>
                <div class="form-group">

                    <textarea type="text" class="form-control" formControlName="descripcion"
                        placeholder="Descripción de tu negocio" required></textarea>
                    <div *ngIf="registerForm.get('descripcion').invalid  && (registerForm.get('descripcion').dirty )">
                        <small *ngIf="registerForm.get('descripcion').errors.required">
                            Ingresa una descripción de tu negocio
                        </small>
                        <small class="text-danger" *ngIf="registerForm.get('descripcion').hasError('maxlength')">
                            Ingresa un máximo de 180 caracteres
                        </small>
                    </div>
                </div>
                <div class="form-group">
                    <!-- <input class="form-control" ngx-google-places-autocomplete [options]='' placeholder="Ubicación(requerido)"
                      #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)" formControlName="ubicacion" /> -->
                      <input class="form-control" ngx-google-places-autocomplete [options]="{ types: ['(cities)'], componentRestrictions: { country: 'CA' }}" 
                       #placesRef="ngx-places"  formControlName="ubicacion" placeholder="Selecciona la ubicación de tu negocio" 
                       (onAddressChange)="handleAddressChange($event)" />
                    <div class="alert alert-danger"
                      *ngIf="registerForm.get('ubicacion').invalid  && (registerForm.get('ubicacion').dirty || registerForm.get('ubicacion').touched)">
                      <div *ngIf="registerForm.get('ubicacion').errors.required">
                        Ingresa una ubicación
                      </div>
                    </div>
                  </div>
                <!-- <div class="form-group">
                    <select #provincia class="custom-select form-control" formControlName="provinciaId"
                        placeholder="Provincia" (change)='seleccionarProvincia(provincia.value)'>
                        <option value="0">Selecciona la provincia</option>
                        <option value="{{item._id}}" *ngFor="let item of provincias">{{item.nombre}}</option>
                    </select>

                </div>
                <div class="form-group">
                    <select #ciudad class="custom-select form-control" formControlName="ciudadId" placeholder="Ciudad">
                        <option value="0">Selecciona una ciudad</option>
                        <option value="{{item._id}}" *ngFor="let item of ciudades">{{item.nombre}}</option>
                    </select>

                </div> -->
                <div class="form-group">
                    <input type="text" class="form-control" formControlName="direccion"
                        placeholder="Dirección de tu negocio" required>
                    <div *ngIf="registerForm.get('direccion').invalid  && (registerForm.get('direccion').dirty )">
                        <small class="text-danger" *ngIf="registerForm.get('direccion').errors.required">
                            Ingresa la dirección de tu negocio
                        </small>
                        <small class="text-danger" class="text-danger"
                            *ngIf="registerForm.get('direccion').hasError('maxlength')">
                            Ingresa un máximo de 90 caracteres
                        </small>
                    </div>
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" formControlName="telefono"
                        placeholder="Teléfono del negocio (El teléfono debe poder recibir SMS para validación)">
                    <div *ngIf="registerForm.get('telefono').invalid  && (registerForm.get('telefono').dirty )">
                        <small class="text-danger" *ngIf="registerForm.get('telefono').errors.required">
                            Ingresa el teléfono de tu negocio
                        </small>
                        <small class="text-danger" *ngIf="registerForm.get('telefono').hasError('pattern')">
                            Ingresa un número válido a 10 dígitos
                        </small>
                    </div>
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" formControlName="correoNegocio"
                        placeholder="Correo electrónico del negocio" required>
                    <div
                        *ngIf="registerForm.get('correoNegocio').invalid  && (registerForm.get('correoNegocio').dirty )">
                        <small class="text-danger" *ngIf="registerForm.get('correoNegocio').errors.required">
                            Ingresa un correo electrónico para tu negocio
                        </small>
                        <small class="text-danger" *ngIf="registerForm.get('correoNegocio').errors.email">
                            Ingresa un correo electrónico válido
                        </small>
                    </div>
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" formControlName="propietario"
                        placeholder="Nombre del propietario del negocio" required>
                    <div *ngIf="registerForm.get('propietario').invalid  && (registerForm.get('propietario').dirty )">
                        <small class="text-danger" *ngIf="registerForm.get('propietario').errors.required">
                            Ingresa el nombre del propietario
                        </small>
                        <small class="text-danger" *ngIf="registerForm.get('propietario').hasError('maxlength')">
                            Ingresa un máximo de 90 caracteres
                        </small>
                    </div>
                </div>
                <div class="form-group">
                    Selecciona un logo
                    <input *ngIf="!isUploading && imagesToUpload.length < 5 " id="file" type="file"
                        class="btn btn-secondary" accept="image/x-png,image/gif,image/jpeg"
                        (change)="ComprimirImagenSeleccionada($event)">
                    <label *ngIf="isUploading" for="">Cargando imagen...</label>
                    <!-- <div class="row" [sortablejs]="imagesToUpload">
                        <div class="col-4" *ngFor="let item of imagesToUpload; let i = index" style="margin-top: 5px;">
                            <i class="icon_trash_alt icon-rel" (click)="eliminarImgCargar(item)"></i>
                            <span class="icon-relw">{{i + 1}}</span>
                            <img [src]="item" alt="" style="cursor: pointer;" class="img-edit img-fluid" width="200">
                        </div>

                    </div> -->
                </div>
                <div class="form-group">
                    <label for=""></label>
                    <ng-select class="custom-select form-control" [addTag]="true" style="color:black;" [items]="subs"
                        bindLabel="nombre" appendTo="body" multiple="true" formControlName="tags"
                        placeholder="Ingresa las etiquetas para tu negocio">
                    </ng-select>
                </div>
                <div class="form-group" class="text-center">
                    <!-- <div class="btn-continue-pr center-div" style="margin-top: 15px;" (click)="continue()">Continuar</div> -->
                    <!-- [disabled]="registerForm.invalid" -->
                    <div class="alert alert-danger" *ngIf="this.message && !this.messageSuccess"
                        style="margin-top: 10px;">
                        {{this.message}}
                    </div>

                    <div class="alert alert-success" *ngIf="this.messageSuccess && !this.message"
                        style="margin-top: 10px;">
                        {{this.messageSuccess}}
                    </div>
                    <div [hidden]="windowRef.confirmationResult" id="phone-sign-in-recaptcha"></div>
                    <div *ngIf="windowRef.confirmationResult">

                        <!-- <label for="code">Enter your Verification Code Here</label><br> -->
                        <input class="form-control" placeholder="Ingresa el código que enviamos a tu celular"
                            type="text" name="code" formControlName="verificationCode">

                        <!-- <button (click)="verifyLoginCode()" class="btn btn-lt-danger">Verify</button> -->
                    </div>
                    <div *ngIf="windowRef.confirmationResult" class="alert alert-warning" role="alert"
                        style="margin-top: 6px;">
                        Ingresa el código que enviamos a tu celular <a href="" style="cursor: pointer;font-size: bold;"
                            (click)="reenviar()"> Reenviar codigo</a>
                    </div>
                    <button *ngIf="windowRef.confirmationResult" type="button" class="btn btn-lt-danger"
                        (click)="verifyLoginCode()" [disabled]="isloading || registerForm.invalid "
                        [ngClass]="{'disabled-class': isloading || registerForm.invalid }">
                        <img src="../../../assets/loadinggif.svg" alt="" width="22" *ngIf="isloading">
                        <span *ngIf="isloading">Cargando</span>
                        <span *ngIf="!isloading">Validar teléfono</span>
                    </button>
                    <button *ngIf="!windowRef.confirmationResult" type="button" class="btn btn-lt-danger"
                        (click)="EnviarTelefono()" [disabled]="isloading || registerForm.invalid"
                        [ngClass]="{'disabled-class': isloading || registerForm.invalid}">
                        <img src="../../../assets/loadinggif.svg" alt="" width="22" *ngIf="isloading">
                        <span *ngIf="isloading">Cargando</span>
                        <span *ngIf="!isloading">Enviar solicitud</span>
                    </button>

                </div>
            </form>

            <!-- <button (click)="EnviarTelefono()">asdasd</button>
            <div *ngIf="windowRef.confirmationResult">
                <hr>
                <label for="code">Enter your Verification Code Here</label><br>
                <input type="text" name="code" [(ngModel)]="verificationCode">
            
                <button (click)="verifyLoginCode()" class="btn btn-sm btn-primary">Verify</button>
              </div> -->
        </div>
    </div>
</div>