import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-eula',
  templateUrl: './eula.component.html',
  styleUrls: ['./eula.component.css']
})
export class EulaComponent implements OnInit {

  constructor( private deviceService: DeviceDetectorService,private app: AppComponent) {
    if(this.deviceService.isMobile()){
      app.activarMobiel(false);
    }
   }

  ngOnInit(): void {
  }

}
