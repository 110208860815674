<div class="container" style="padding: 30px;">
  <p class="h3"> Editar anuncio</p>
  <div class="row">
    <div class="col-lg-6">
      <div class="row">
        <div class="col-4" *ngFor="let item of imagenes">
          <i class="icon_trash_alt icon-rel" (click)="eliminarImagen(item)"></i>
          <img [src]="item" alt="" class="img-edit img-fluid" routerLink="/detalle/">
        </div>
      </div>

    </div>
    <div class="col-lg-6">
      <!-- <button (click)="fileChangeEvent($event)" class="btn btn-secondary">Elegir imagen</button> -->
      <input  id="file" type="file"
        class="btn btn-secondary" multiple="" accept="image/x-png,image/gif,image/jpeg" (change)="fileChangeEvent($event)">
      <div class="row">
        <div class="col-4" *ngFor="let item of imagesToUpload" style="margin-top: 5px;">
          <i class="icon_trash_alt icon-rel" (click)="eliminarImgCargar(item)"></i>
          <img [src]="item" alt="" class="img-edit img-fluid">
        </div>
      </div>
      <!-- <img *ngIf="imgResultAfterCompress" [src]="imgResultAfterCompress" alt="" style="width: 100%;"> -->
      <!-- Inmuebles -->
      <form [formGroup]="anuncioForm" (ngSubmit)="publicar()" *ngIf="this.verInmuebles">

        <div style="margin-top: 5px;">
          <select class="custom-select form-control" placeholder="Tipo de inmueble" formControlName="tipoInmueble">
            <option value="1">Casa</option>
            <option value="2">Departamento</option>
            <option value="3">Terreno</option>
            <option value="4">Oficina/Local</option>
            <option value="5">Bodegas</option>
            <option value="6">Otros</option>
          </select>
          <div class="alert alert-danger"
            *ngIf="anuncioForm.get('tipoInmueble').invalid  && (anuncioForm.get('tipoInmueble').dirty )">
            <div *ngIf="anuncioForm.get('tipoInmueble').errors.required">
              Ingresa una tipo de inmueble
            </div>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <input type="number" class="form-control" formControlName="numHabitaciones"
            placeholder="Número de habitaciones(requerido)" required>
          <div class="alert alert-danger"
            *ngIf="anuncioForm.get('numHabitaciones').invalid  && anuncioForm.get('numHabitaciones').dirty">
            <div *ngIf="anuncioForm.get('numHabitaciones').errors.required">
              Ingrese un número de habitaciones
            </div>
            <div *ngIf="anuncioForm.get('numHabitaciones').hasError('pattern')">
              Ingresa sólo números
            </div>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <input type="number" class="form-control" formControlName="numBanios" placeholder="Número de baños(requerido)"
            required>
          <div class="alert alert-danger"
            *ngIf="anuncioForm.get('numBanios').invalid  && anuncioForm.get('numBanios').dirty">
            <div *ngIf="anuncioForm.get('numBanios').errors.required">
              Ingresa un número de baños
            </div>
            <div *ngIf="anuncioForm.get('numBanios').hasError('pattern')">
              Ingresa sólo números
            </div>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <input type="number" class="form-control" formControlName="estacionamientos"
            placeholder="Número de estacionamienos(requerido)" required>
          <div class="alert alert-danger"
            *ngIf="anuncioForm.get('estacionamientos').invalid  && (anuncioForm.get('estacionamientos').dirty )">
            <div *ngIf="anuncioForm.get('estacionamientos').errors.required">
              Ingresa un número de estacionamientos
            </div>
            <div *ngIf="anuncioForm.get('estacionamientos').hasError('pattern')">
              Ingresa sólo números
            </div>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <input type="number" class="form-control" formControlName="superficie"
            placeholder="Superficie construida (m2)(requerido)" required>
          <div class="alert alert-danger"
            *ngIf="anuncioForm.get('superficie').invalid  && (anuncioForm.get('superficie').dirty )">
            <div *ngIf="anuncioForm.get('superficie').errors.required">
              Ingresa una superficie
            </div>
            <div *ngIf="anuncioForm.get('superficie').hasError('pattern')">
              Ingresa sólo números
            </div>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <input type="text" class="form-control" formControlName="titulo" placeholder="Título(requerido)" required>
          <div class="alert alert-danger" *ngIf="anuncioForm.get('titulo').invalid  && anuncioForm.get('titulo').dirty">
            <div *ngIf="anuncioForm.get('titulo').errors.required">
              Ingresa un título de anuncio
            </div>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <input type="text" class="form-control" formControlName="descripcion"
            placeholder="Descripción de tu anuncio(requerido)" required>
          <div class="alert alert-danger"
            *ngIf="anuncioForm.get('descripcion').invalid  && anuncioForm.get('descripcion').dirty">
            <div *ngIf="anuncioForm.get('descripcion').errors.required">
              Ingresa una descripción de tu anuncio
            </div>
          </div>
        </div>


        <div style="margin-top: 5px;">
          <input class="form-control" ngx-google-places-autocomplete [options]='' placeholder="Ubicación(requerido)"
            #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)" formControlName="ubicacion" />
          <div class="alert alert-danger"
            *ngIf="anuncioForm.get('ubicacion').invalid  && (anuncioForm.get('ubicacion').dirty || anuncioForm.get('ubicacion').touched)">
            <div *ngIf="anuncioForm.get('ubicacion').errors.required">
              Ingresa una ubicación
            </div>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" formControlName="esGratuito" class="custom-control-input" id="customCheck2">
            <label class="custom-control-label" for="customCheck2">Ofrecer gratuitamente</label>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <input type="text" class="form-control" formControlName="precio" placeholder="Precio(requerido)" required>
          <div class="alert alert-danger" *ngIf="anuncioForm.get('precio').invalid  && anuncioForm.get('precio').dirty">
            <div *ngIf="anuncioForm.get('precio').errors.required">
              Ingresa un precio a tu anuncio
            </div>
            <div *ngIf="anuncioForm.get('precio').hasError('pattern')">
              Ingresa sólo números
            </div>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" formControlName="mostrarTelefono"  class="custom-control-input" id="customCheck11">
            <label class="custom-control-label" for="customCheck11">Mostrar mi número telefónico</label>
          </div>
        </div>

        <div class="text-center">
          <!-- <div class="btn-continue-pr center-div" style="margin-top: 15px;" (click)="continue()">Continuar</div> -->
          <!-- [disabled]="anuncioForm.invalid" -->
          <div class="alert alert-danger" *ngIf="this.message && !this.messageSuccess" style="margin-top: 10px;">
            {{this.message}}
          </div>

          <div class="alert alert-success" *ngIf="this.messageSuccess && !this.message" style="margin-top: 10px;">
            {{this.messageSuccess}}
          </div>
          <!-- <input ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)"/> -->

          <button type="submit" class="btn btn-lt-danger">
            <div *ngIf="isLoading" class="spinner-border btn-spinner" role="status">
            </div>
            <span>Publicar</span>
          </button>

        </div>
      </form>

      <!-- Autos -->
      <form [formGroup]="autosForm" (ngSubmit)="publicar()" *ngIf="this.verAutos">

        <div style="margin-top: 5px;">
          <label for="">Marca</label>
          <input type="text" class="form-control" formControlName="marca" placeholder="Marca(requerido)" required>
          <div class="alert alert-danger" *ngIf="autosForm.get('marca').invalid  && autosForm.get('marca').dirty">
            <div *ngIf="autosForm.get('marca').errors.required">
              Ingrese un número de habitaciones
            </div>
            <div *ngIf="autosForm.get('marca').hasError('pattern')">
              Ingresa sólo números
            </div>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <label for="">Modelo</label>
          <input type="text" class="form-control" formControlName="marcaAuto" placeholder="Modelo(requerido)" required>
          <div class="alert alert-danger"
            *ngIf="autosForm.get('marcaAuto').invalid  && autosForm.get('marcaAuto').dirty">
            <div *ngIf="autosForm.get('marcaAuto').errors.required">
              Ingresa un número de baños
            </div>
            <div *ngIf="autosForm.get('marcaAuto').hasError('pattern')">
              Ingresa sólo números
            </div>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <label for="">Año</label>
          <input type="number" class="form-control" formControlName="anioAuto" placeholder="Año(requerido)" required>
          <div class="alert alert-danger"
            *ngIf="autosForm.get('anioAuto').invalid  && (autosForm.get('anioAuto').dirty )">
            <div *ngIf="autosForm.get('anioAuto').errors.required">
              Ingresa un número de estacionamientos
            </div>
            <div *ngIf="autosForm.get('anioAuto').hasError('pattern')">
              Ingresa sólo números
            </div>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <label for="">Tipo de transmisión</label>
          <input type="text" class="form-control" formControlName="transmision"
            placeholder="Tipo de transmisión(requerido)" required>
          <div class="alert alert-danger"
            *ngIf="autosForm.get('transmision').invalid  && (autosForm.get('transmision').dirty )">
            <div *ngIf="autosForm.get('transmision').errors.required">
              Ingresa una superficie
            </div>
            <div *ngIf="autosForm.get('transmision').hasError('pattern')">
              Ingresa sólo números
            </div>
          </div>
        </div>

        <div style="margin-top: 5px;">
          Tipo de combustible
          <input type="text" class="form-control" formControlName="combustible"
            placeholder="Tipo de combustible(requerido)" required>
          <div class="alert alert-danger"
            *ngIf="autosForm.get('combustible').invalid  && (autosForm.get('combustible').dirty )">
            <div *ngIf="autosForm.get('combustible').errors.required">
              Ingresa una superficie
            </div>
            <div *ngIf="autosForm.get('combustible').hasError('pattern')">
              Ingresa sólo números
            </div>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <label for="">Kilómetraje</label>
          <input type="text" class="form-control" formControlName="kilometraje" placeholder="Kilometraje(requerido)"
            required>
          <div class="alert alert-danger"
            *ngIf="autosForm.get('kilometraje').invalid  && (autosForm.get('kilometraje').dirty )">
            <div *ngIf="autosForm.get('kilometraje').errors.required">
              Ingresa una superficie
            </div>
            <div *ngIf="autosForm.get('kilometraje').hasError('pattern')">
              Ingresa sólo números
            </div>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <label for="">Título</label>
          <input type="text" class="form-control" formControlName="titulo" placeholder="Título(requerido)" required>
          <div class="alert alert-danger" *ngIf="autosForm.get('titulo').invalid  && autosForm.get('titulo').dirty">
            <div *ngIf="autosForm.get('titulo').errors.required">
              Ingresa un título de anuncio
            </div>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <label for="">Descripcíon</label>
          <input type="text" class="form-control" formControlName="descripcion"
            placeholder="Descripción de tu anuncio(requerido)" required>
          <div class="alert alert-danger"
            *ngIf="autosForm.get('descripcion').invalid  && autosForm.get('descripcion').dirty">
            <div *ngIf="autosForm.get('descripcion').errors.required">
              Ingresa una descripción de tu anuncio
            </div>
          </div>
        </div>


        <div style="margin-top: 5px;">
          <label for="">Ubicación</label>
          <input class="form-control" ngx-google-places-autocomplete [options]='' placeholder="Ubicación(requerido)"
            #placesRef="ngx-places" (input)="handleAddressChange($event)"
            (onAddressChange)="handleAddressChange($event)" formControlName="ubicacion" />
          <div class="alert alert-danger"
            *ngIf="autosForm.get('ubicacion').invalid  && (autosForm.get('ubicacion').dirty || autosForm.get('ubicacion').touched)">
            <div *ngIf="autosForm.get('ubicacion').errors.required">
              Ingresa una ubicación
            </div>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" formControlName="esGratuito" class="custom-control-input" id="customCheck4">
            <label class="custom-control-label" for="customCheck4">Ofrecer gratuitamente</label>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <label for="">Precio</label>
          <input type="text" class="form-control" formControlName="precio" placeholder="Precio(requerido)" required>
          <div class="alert alert-danger" *ngIf="autosForm.get('precio').invalid  && autosForm.get('precio').dirty">
            <div *ngIf="autosForm.get('precio').errors.required">
              Ingresa un precio a tu anuncio
            </div>
            <div *ngIf="autosForm.get('precio').hasError('pattern')">
              Ingresa sólo números
            </div>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <div class="custom-control custom-checkbox">
            <input type="checkbox"  formControlName="mostrarTelefono" class="custom-control-input" id="customCheck3">
            <label class="custom-control-label" for="customCheck3" >Mostrar mi número telefónico</label>
          </div>
        </div>

        <div class="text-center">
          <div class="alert alert-danger" *ngIf="this.message && !this.messageSuccess" style="margin-top: 10px;">
            {{this.message}}
          </div>
          <div class="alert alert-success" *ngIf="this.messageSuccess && !this.message" style="margin-top: 10px;">
            {{this.messageSuccess}}
          </div>
          <button type="submit" class="btn btn-lt-danger">
            <div *ngIf="isLoading" class="spinner-border btn-spinner" role="status">
            </div>
            <span>Publicar</span>
          </button>

        </div>
      </form>

      <form [formGroup]="anuncioGeneralForm" (ngSubmit)="publicar()" *ngIf="this.verTodo">
        <div style="margin-top: 5px;">
          <select class="custom-select form-control" placeholder="Categorias" formControlName="categoriaId">
            <option value="{{item._id}}" *ngFor="let item of this.categorias">{{item.nombre}}</option>
          </select>
          <div class="alert alert-danger"
            *ngIf="anuncioForm.get('tipoInmueble').invalid  && (anuncioForm.get('tipoInmueble').dirty )">
            <div *ngIf="anuncioForm.get('tipoInmueble').errors.required">
              Ingresa una tipo de inmueble
            </div>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <label for="">Título</label>
          <input type="text" class="form-control" formControlName="titulo" placeholder="Título(requerido)" required>
          <div class="alert alert-danger"
            *ngIf="anuncioGeneralForm.get('titulo').invalid  && anuncioGeneralForm.get('titulo').dirty">
            <div *ngIf="anuncioGeneralForm.get('titulo').errors.required">
              Ingresa un título de anuncio
            </div>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <label for="">Descripción</label>
          <input type="text" class="form-control" formControlName="descripcion"
            placeholder="Descripción de tu anuncio(requerido)" required>
          <div class="alert alert-danger"
            *ngIf="anuncioGeneralForm.get('descripcion').invalid  && anuncioGeneralForm.get('descripcion').dirty">
            <div *ngIf="anuncioGeneralForm.get('descripcion').errors.required">
              Ingresa una descripción de tu anuncio
            </div>
          </div>
        </div>


        <div style="margin-top: 5px;">
          <label for="">Ubicación</label>
          <input class="form-control" ngx-google-places-autocomplete [options]='' placeholder="Ubicación(requerido)"
            #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)" formControlName="ubicacion" />
          <div class="alert alert-danger"
            *ngIf="anuncioGeneralForm.get('ubicacion').invalid  && (anuncioGeneralForm.get('ubicacion').dirty || anuncioGeneralForm.get('ubicacion').touched)">
            <div *ngIf="anuncioGeneralForm.get('ubicacion').errors.required">
              Ingresa una ubicación
            </div>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" formControlName="esGratuito" class="custom-control-input" id="customCheck2">
            <label class="custom-control-label" for="customCheck2">Ofrecer gratuitamente</label>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <label for="">Precio</label>
          <input type="text" class="form-control" formControlName="precio" placeholder="Precio(requerido)" required>
          <div class="alert alert-danger"
            *ngIf="anuncioGeneralForm.get('precio').invalid  && anuncioGeneralForm.get('precio').dirty">
            <div *ngIf="anuncioGeneralForm.get('precio').errors.required">
              Ingresa un precio a tu anuncio
            </div>
            <div *ngIf="anuncioGeneralForm.get('precio').hasError('pattern')">
              Ingresa sólo números
            </div>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <label for="">Mostrar mi teléfono</label>
          <div class="custom-control custom-checkbox">
            <input type="checkbox" formControlName="mostrarTelefono" class="custom-control-input" id="customCheck15">
            <label class="custom-control-label" for="customCheck15">Mostrar mi número telefónico</label>
          </div>
        </div>

        <div class="text-center">
          <div class="alert alert-danger" *ngIf="this.message && !this.messageSuccess" style="margin-top: 10px;">
            {{this.message}}
          </div>
          <div class="alert alert-success" *ngIf="this.messageSuccess && !this.message" style="margin-top: 10px;">
            {{this.messageSuccess}}
          </div>
          <button type="submit" class="btn btn-lt-danger">
            <div *ngIf="isLoading" class="spinner-border btn-spinner" role="status">
            </div>
            <span>Publicar</span>
          </button>

        </div>
      </form>

    </div>
  </div>
</div>

<ngx-spinner  size="default" color="#000" type="ball-beat" [fullScreen]="true">
  <p style="color: white"> Cargando... </p>
</ngx-spinner>