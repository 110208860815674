import { first } from 'rxjs/operators';
import { ApiService } from './../_services/api.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from '../app.component';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-buscaranuncios',
  templateUrl: './buscaranuncios.component.html',
  styleUrls: ['./buscaranuncios.component.css']
})
export class BuscaranunciosComponent implements OnInit {

  anuncios: any = [];

  constructor(private api: ApiService, private activatedRoute: ActivatedRoute,  private toastr: ToastrService,
    private deviceService: DeviceDetectorService,private app: AppComponent) {
    if(this.deviceService.isMobile()){
      app.activarMobiel(true);
    }
    this.activatedRoute.params.subscribe(params => {
      console.log('activatedRoute', params['query']);
      // this.cargarAnuncio(params['id']);  
      this.api.getResponse('buscarAnuncios/' + params['query'], true).pipe(first())
        .subscribe(
          data => {
            this.anuncios = data;
            console.log('busqueda', data);
          }, err => {
          });
    });
  }

  ngOnInit(): void {
  }


  Agregarfavoritos(item) {
    let usuario = {
      usuarioId: localStorage.getItem('idUsuario'),
      anuncioId: item._id
    };

    if (item.tieneFavorito) {
      this.api.deleteResponse('favoritos/' + item._id, false).pipe(first())
        .subscribe(
          data => {
            item.tieneFavorito = !item.tieneFavorito;
            // Swal.fire('LatinosVentas','Se ha quitado a tus favoritos','success');
          }, err => {
          });

    }
    else {
      this.api.postResponse('favoritos', false, usuario).pipe(first())
        .subscribe(
          data => {
            item.tieneFavorito = !item.tieneFavorito;
            // Swal.fire('LatinosVentas','Se ha agregado a tus favoritos','success');
          }, error => {

          })
    }

  }


  agregarFavoritos(item) {
    let usuario = {
      usuarioId: localStorage.getItem('idUsuario'),
      anuncioId: item._id
    };

    console.log(item);

    if (item.tieneFavorito) {
      this.api.deleteResponse('favoritos/' + item._id, true).pipe(first())
        .subscribe(
          data => {
            item.tieneFavorito = !item.tieneFavorito;
            this.toastr.success('LatinosVentas', 'Se ha quitado de tus favoritos');
            //Swal.fire('LatinosVentas','Se ha quitado a tus favoritos','success');
          }, err => {
          });
    }
    else {
      this.api.postResponse('favoritos', true, usuario).pipe(first())
        .subscribe(
          data => {
            item.tieneFavorito = !item.tieneFavorito;
            this.toastr.success('LatinosVentas', 'Se ha agregado a tus favoritos');
            //Swal.fire('LatinosVentas','Se ha agregado a tus favoritos','success');
          }, error => {

          })
    }
  }

}
