import { RestablecerComponent } from './../../@modals/restablecer/restablecer.component';
import { AuthService } from './../../_services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-restablecer-password',
  templateUrl: './restablecer-password.component.html',
  styleUrls: ['./restablecer-password.component.css']
})
export class RestablecerPasswordComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private modal: NgbModal, private auth: AuthService, private deviceService: DeviceDetectorService,private app: AppComponent) {
    if(this.deviceService.isMobile()){
      this.app.activarMobiel(false);
    }
    this.activatedRoute.params.subscribe(params => {
      console.log('activatedRoute', params);
      if (params['id'].length > 15) {
        this.auth.logout();
        this.modal.dismissAll();
        const modalRef = this.modal.open(RestablecerComponent);
        modalRef.componentInstance.data = params['id'];
        modalRef.result.then((result) => {
          console.log(result);
        }, (reason) => {
        });
      }
    });
  }

  ngOnInit(): void {
  }

}
