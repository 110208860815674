<!-- DESCUBRE -->

<section class="women-banner spad container-fluid" *ngIf="!loading && negocios.length > 0" style="padding-bottom: 0px;">
  <div>
    <br>
    <h3 class="section-title">Directorio Latino</h3>
  </div>
  <div class="">
    <owl-carousel-o [options]="customOptions" style=" height: 300px !important;">
      <ng-template   carouselSlide [width]="item.merge" *ngFor="let item of negocios" style=" height: 300px !important;">
        <!-- <div class="product-slider shadow-box"  >
          <div class="product-item">
            <div class="pi-pic" style="background-color: #f9f9f9;cursor: pointer;">
              <img [src]="item.logo" alt=""  class="img-anuncio" style="object-fit: contain !important;">
            </div>
              <div style="text-align: center;padding: 6px; color: black;font-weight: bold; height: 132px;">
                <span  style=" text-decoration: none;">{{item.nombre | slice:0:30}} <span *ngIf="item.nombre.length >=20">...</span></span>
                <p>{{item.descripcion | slice:0:50}}</p><span *ngIf="item.nombre.length >=50">...</span>
                <p>{{item.telefono}}</p>
              </div>
          </div>
        </div> -->
        <div class="row" style="margin-bottom: 100px; height: 300px;">
          <div *ngIf="negocios.length <= 0" class="center-div text-center">
              <img src="../../assets/img/notfound.png" alt="">
              <div class="h1">No encontramos resultados</div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="product-slider shadow-box" style="min-width: 590px !important;margin-left: 10px;">
                  <div class="row">
                      <div class="col-lg-5  col-md-4 col-sm-4 col-xs-12">
                          <div class="pi-pic" style="background-color: #f9f9f9;cursor: pointer;">
                              <img [src]="item.logo" alt="" class="img-anuncio img-fluid">

                              <ul>
                              </ul>
                          </div>
                      </div>
                      <div class="col-lg-7  col-md-8 col-sm-8 col-xs-12" style="padding: 10px 25px 5px 5px;">
                          <div class="product-item">
                              <div>
                                  <span
                                      style="font-size: 20px; text-decoration: none; color:#e7ab3c;">{{item.nombre}}</span>
                                  <p style="font-size: 15px; text-decoration: none; color:#292929 ;">
                                      {{item.direccion}} - TEL: {{item.telefono}}</p>
                                  <p
                                      style="font-size: 15px; text-decoration: none; color:#282828;word-wrap: break-word;line-height: normal;">
                                      {{item.descripcion | slice:0:180}}<span *ngIf="item.descripcion.length > 180" style="font-size: 15px; text-decoration: none; color:#6d6d6d">...</span> </p>
                                      
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      </ng-template>
    </owl-carousel-o>
  </div>




</section>
<div class="text-center">
  <hr>
  <h3>Encuentra cientos de artículos para comprar o vender</h3>
  <hr>
</div>
<!-- FAVORITOS -->
<section class="women-banner spad container" *ngIf="!loading">

  <div *ngFor="let item of this.categorias" style="margin-bottom: 35px;">
    <h3 class="section-title" style="cursor:pointer;" routerLink="{{abrirProductos(item._id)}}" *ngIf="cargarAnunciosCat(item._id).length > 0">{{item.nombre}}</h3>
    <div class="" *ngIf="cargarAnunciosCat(item._id).length > 0">
      <div class="row gallery">
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-6 " *ngFor="let item of cargarAnunciosCat(item._id)">
          <div class="product-slider shadow-box"  style="  height: 350px;">
            
              <div class="product-item">
                <div class="pi-pic" style="background-color: #f9f9f9;cursor: pointer;">
                  <!-- <div class="landscape"> -->
                  <img [src]="item.imagenes[0]" alt="" class="img-anuncio" routerLink="/detalle/{{item._id}}">
                  <!-- </div> -->
                  <!-- <div class="sale">Sale</div> -->
                  <div class="icon" style="background-color: white;padding: 10px;border: none;margin-top: -16px;">
                    <i class="icon_heart" *ngIf="item.tieneFavorito" style="color: red;"
                      (click)="agregarFavoritos(item)"></i>
                    <i class="icon_heart" *ngIf="!item.tieneFavorito" (click)="agregarFavoritos(item)"></i>
                  </div>
                  <!-- <ul>
                      <li class="w-icon active"><a href="#"><i class="icon_bag_alt"></i></a></li>
                      <li class="quick-view"><a routerLink="/detalle/{{item._id}}" >Vista rápida</a></li>
                      <li class="w-icon"><a href="#"><i class="fa fa-random"></i></a></li>
                    </ul> -->
                </div>
                <a routerLink="/detalle/{{item._id}}">
                <div class="pi-text">
                  <!-- <div class="catagory-name">{{item.titulo}}</div> -->
                  <span style="font-size: 16px; text-decoration: none; color:black ;">{{item.titulo | slice:0:35}} <span *ngIf="item.titulo.length >=35">...</span></span>
                  <div *ngIf="!item.esGratuito" class="product-price">
                    {{item.precio | currency : 'USD' : 'symbol' : '1.0-0'}}
                    <!-- <span>$35.00</span> -->
                  </div>
                  <div *ngIf="item.esGratuito" class="product-price">
                    Gratis
                  </div>
                </div>
              </a>
              </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="">
    <h3 class="section-title">Descubre</h3>
  </div> -->
  <!-- <div class="">
    <div class="row gallery">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-6 " *ngFor="let item of cargarAnunciosCat('609aa9f65063891bdcfb8939')">
        <div class="product-slider shadow-box"  style="  height: 350px;">
          
            <div class="product-item">
              <div class="pi-pic" style="background-color: #f9f9f9;cursor: pointer;">
                <img [src]="item.imagenes[0]" alt="" class="img-anuncio" routerLink="/detalle/{{item._id}}">

                <div class="icon" style="background-color: white;padding: 10px;border: none;margin-top: -16px;">
                  <i class="icon_heart" *ngIf="item.tieneFavorito" style="color: red;"
                    (click)="agregarFavoritos(item)"></i>
                  <i class="icon_heart" *ngIf="!item.tieneFavorito" (click)="agregarFavoritos(item)"></i>
                </div>

              </div>
              <a routerLink="/detalle/{{item._id}}">
              <div class="pi-text">
                <span style="font-size: 16px; text-decoration: none; color:black ;">{{item.titulo | slice:0:35}} <span *ngIf="item.titulo.length >=35">...</span></span>
                <div *ngIf="!item.esGratuito" class="product-price">
                  {{item.precio | currency : 'USD' : 'symbol' : '1.0-0'}}
                </div>
                <div *ngIf="item.esGratuito" class="product-price">
                  Gratis
                </div>
              </div>
            </a>
            </div>
        </div>
      </div>
    </div>
  </div> -->
</section>


<ngx-spinner bdColor="rgba(0,0,0,0.01)" name="spinner-popular" size="default" color="#57b1b9" type="ball-beat"
  [fullScreen]="false">
  <p style="color: white"> Loading... </p>
</ngx-spinner>