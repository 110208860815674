<div class="container">
  <div class="row">
    <div class="left col-lg-4" style="max-height: 390px;min-height: 390px">

      <div class="photo-left" style="    margin-top: 145px;">
        <img *ngIf="this.perfil?.imagen" class="photo" [src]="this.perfil?.imagen" style="object-fit: cover;" />
        <img *ngIf="!this.perfil?.imagen" class="photo" src="../../../assets/img/profile.png"
          style="object-fit: cover;" />
      </div>
      <p class="name h4" *ngIf="this.perfil?.nombre">
        {{this.perfil.nombre}} {{this.perfil.apellido}}
      </p>
      <!-- <p class="info" *ngIf="this.perfil.nomusuario">{{this.perfil.nomusuario}}</p>
      <p class="info" *ngIf="this.perfil.telefono">{{this.perfil.telefono}}</p> -->
      <p class="info" *ngIf="this.perfil?.correo">{{this.perfil.correo}}</p>
      <p class="info">
        <button type="button" (click)="logout()" class="btn-yellow">Cerrar sesión</button>
        <!-- <a style="cursor:pointer;" class="login-panel" (click)="logout()"> Cerrar sesión</a> -->
      </p>
    </div>
    <div class="right col-lg-8">
      <ul class="nav">
        <li class="li-nonactive"><a class="a-nonactive" routerLink="MisAnuncios" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}">Mis anuncios</a></li>
        <li class="li-nonactive"><a class="a-nonactive" routerLink="Mensajes" routerLink="Mensajes"
            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Mensajes</a></li>
        <li class="li-nonactive"> <a class="a-nonactive" routerLink="Favoritos" routerLink="Favoritos"
            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Favoritos</a></li>
        <li class="li-nonactive"> <a class="a-nonactive" routerLink="MisNegocios" routerLink="MisNegocios"
            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Mis Negocios</a></li>
        <li class="li-nonactive"> <a class="a-nonactive" routerLink="Editar" routerLink="Editar"
            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Perfil</a></li>

      </ul>
      <hr>
      <router-outlet></router-outlet>
    </div>
  </div>