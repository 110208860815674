<div class="container ">
    <div class="sub-list">
      <div class="row ">
        <div *ngIf="anuncios.length <= 0" class="center-div text-center">
          <img src="../../assets/img/notfound.png" alt="">
          <div class="h1">No encontramos resultados</div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-6 " *ngFor="let item of anuncios" >
          <div class="product-slider shadow-box" style="  height: 350px;">

            <div class="product-item">
              <div class="pi-pic" style="background-color: #f9f9f9;cursor: pointer;">
                <img [src]="item.imagenes[0]" alt="" routerLink="/detalle/{{item._id}}" class="img-anuncio">
                <div class="icon"  style="background-color: white;padding: 10px;border: none;margin-top: -16px;">
                  <i class="icon_heart" *ngIf="item.tieneFavorito" style="color: red;"
                    (click)="agregarFavoritos(item)"></i>
                  <i class="icon_heart" *ngIf="!item.tieneFavorito" (click)="agregarFavoritos(item)"></i>
                </div>
                <ul>
                </ul>
              </div>
              <a routerLink="/detalle/{{item._id}}">
                <div class="pi-text">
                  <span style="font-size: 16px; text-decoration: none; color:black ;">{{item.titulo | slice:0:20}} <span *ngIf="item.titulo.length >=20">...</span></span>
                  <div *ngIf="!item.esGratuito" class="product-price">
                    {{item.precio | currency : 'USD' : 'symbol' : '1.0-0'}}
                  </div>
                  <div *ngIf="item.esGratuito" class="product-price">
                    Gratis
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>