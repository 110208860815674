import { AppComponent } from './../../app.component';
import { RecuperarComponent } from './../recuperar/recuperar.component';
import Swal from 'sweetalert2';
import { AuthService } from './../../_services/auth.service';
import { first } from 'rxjs/operators';
import { ApiService } from './../../_services/api.service';
import * as firebase from 'firebase';
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  iniciarForm: FormGroup;
  isLoading: boolean = false;
  message: String = '';
  fbUrl: string = 'https://graph.facebook.com/me?fields=id,name,first_name,last_name,email&access_token=';
  fbTok: string = 'EAAL6mZAuKlCQBAC5IRXeOvQfKiWGOOGvWxURZALY4FoZA8dTNZAlxKFP4ouvGJL20rK4NbZA5xzVW30fUZAtX02dZALCq6PBnucJI72BhaXZAtPGywGRFPh386aYz1ZBXDzJZBZCinkFN1P3ZAZAs4Nl4JsfSkGDk1hT90YU0zkIrk78gPTkZBZAbcJNAU8biTaMrZB2xfOVyzJZAm72Angl1mo1u84hcLf5fPw7T59AZD';

  constructor(public auth: AngularFireAuth, private api: ApiService, private authService: AuthService,
    private modal: NgbModal, private appC: AppComponent, private toastr: ToastrService, private router: Router) {
    this.iniciarForm = new FormGroup({
      correo: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
      firebaseToken: new FormControl(localStorage.getItem('pushToken'), [])
    });
  }

  ngOnInit(): void {

  }
  paramsX: any;
  usuarioX: any;
  idUser: any;
  async facebookLogin() {

    if (localStorage.getItem('pushToken') == null || localStorage.getItem('pushToken') == undefined || localStorage.getItem('pushToken') == '' || localStorage.getItem('pushToken') == 'null') {
      Swal.fire('LatinosVentas', 'No hemos podido iniciar sesión, por favor intenta nuevamente', 'warning');
      location.reload();
      return;
    }
    var urlImgFb = '';

    await this.auth.signInWithPopup(new firebase.default.auth.FacebookAuthProvider().setCustomParameters({ auth_type: 'reauthenticate' })).then((data) => {
      console.log('login en facebook', data);
      urlImgFb = data.user.photoURL + "/?type=large";
      this.paramsX = {
        fb_id: data.user.uid,
        fb_token: data.credential.accessToken,
        uid: '',
        correo: '',
        userId: '',
        imagen: urlImgFb,
        firebase_token: localStorage.getItem('pushToken')
      };
      this.usuarioX = {
        correo: data.user.email,
        imagen: urlImgFb,
        usuarioFb: true,
        usuarioValido: true,

      }

      this.api.getResponse('usuarioEmailFB/' + this.usuarioX.correo).pipe(first())
        .subscribe(
          dataUser => {
            console.log('usuarioEmail', dataUser);
            this.paramsX.uid = dataUser._id;
            this.paramsX.correo = dataUser.correo.toString();
            this.paramsX.userId = dataUser._id;
            this.paramsX.imagen = urlImgFb;
            console.log(this.paramsX);
            if (dataUser.usuarioFb) {
              this.api.postResponse('loginfb/', false, this.paramsX).pipe(first())
                .subscribe(
                  data => {
                    console.log('loginfb', data);
                    localStorage.setItem('idUsuario', data['user'].userId);
                    this.authService.guardarSesion(data['token'], data['user'].userId, this.paramsX.correo, data['user'].nomusuario);
                    this.modal.dismissAll();
                  })
            }
            else {
              console.log('entraregistro1', this.usuarioX);
              this.api.postResponse('registro/', false, this.usuarioX).pipe(first())
                .subscribe(
                  data => {
                    console.log('registro1', data);
                    this.idUser = data['_id'];

                    this.api.postResponse('loginfb/', false, this.paramsX).pipe(first())
                      .subscribe(
                        data => {
                          console.log('loginfb', data);
                          localStorage.setItem('idUsuario', this.idUser);
                          this.authService.guardarSesion(data['token'], this.idUser, this.paramsX.correo, data['user'].nomusuario);
                          this.modal.dismissAll();
                        })
                  }, error => {
                    console.log('error2,', error);
                    this.message = error.error.mensaje;
                  });
            }
          }, error => {
            console.log('error al ingresar', error);
            if (error.error.estatus == 404) {
              this.api.postResponse('registro/', false, this.usuarioX).pipe(first())
                .subscribe(
                  data => {
                    this.idUser = data['_id'];
                    this.api.postResponse('loginfb/', false, this.paramsX).pipe(first())
                      .subscribe(
                        data => {
                          localStorage.setItem('idUsuario', this.idUser);
                          this.authService.guardarSesion(data['token'], this.idUser, this.usuarioX.correo, data['user'].nomusuario);
                          this.modal.dismissAll();
                        })
                  }, error => {
                    console.log('otro error', error);
                  });
            }

          })
    }).catch((error) => {
      console.log('error en facebook', error);
    })
  }

  googleLogin() {
    if (localStorage.getItem('pushToken') == null || localStorage.getItem('pushToken') == undefined || localStorage.getItem('pushToken') == '' || localStorage.getItem('pushToken') == 'null') {
      Swal.fire('LatinosVentas', 'No hemos podido iniciar sesión, por favor intenta nuevamente', 'warning');
      location.reload();
      return;
    }

    return this.auth.setPersistence(firebase.default.auth.Auth.Persistence.LOCAL)
      .then(() => {
        this.auth.signInWithPopup(new firebase.default.auth.GoogleAuthProvider().setCustomParameters({ auth_type: 'reauthenticate' })).then((data) => {
          console.log('login with fb params', data);
          this.paramsX = {
            fb_id: data.user.uid,
            fb_token: data.credential.accessToken,
            uid: '',
            correo: '',
            userId: '',
            firebase_token: localStorage.getItem('pushToken')
          };

          this.usuarioX = {
            correo: data.user.email,
            usuarioFb: true,
            usuarioValido: true
          }
          this.api.getResponse('usuarioEmailFB/' + this.usuarioX.correo).pipe(first())
            .subscribe(
              dataUser => {
                console.log('usuarioEmail', dataUser);
                this.paramsX.uid = dataUser._id;
                this.paramsX.correo = dataUser.correo.toString();
                this.paramsX.userId = dataUser._id
                console.log(this.paramsX);
                if (dataUser.usuarioFb) {
                  this.api.postResponse('loginfb/', false, this.paramsX).pipe(first())
                    .subscribe(
                      data => {
                        console.log('loginfb', data);
                        localStorage.setItem('idUsuario', data['user'].userId);
                        this.authService.guardarSesion(data['token'], data['user'].userId, this.paramsX.correo, data['user'].nomusuario);
                        this.modal.dismissAll();
                      })
                }
                else {
                  this.api.postResponse('registro/', false, this.usuarioX).pipe(first())
                    .subscribe(
                      data => {
                        this.idUser = data['_id'];
                        this.api.postResponse('loginfb/', false, this.paramsX).pipe(first())
                          .subscribe(
                            data => {
                              localStorage.setItem('idUsuario', this.idUser);
                              this.authService.guardarSesion(data['token'], this.idUser, this.paramsX.correo, data['user'].nomusuario);
                              this.modal.dismissAll();
                            })
                      }, error => {
                        console.log('error al ingresar1', error);
                        this.message = error.error.mensaje;
                      });
                }
              }, error => {
                console.log('error al ingresar2', error);
                if (error.error.estatus == 404) {
                  this.api.postResponse('registro/', false, this.usuarioX).pipe(first())
                    .subscribe(
                      data => {
                        this.idUser = data['_id'];
                        this.api.postResponse('loginfb/', false, this.paramsX).pipe(first())
                          .subscribe(
                            data => {
                              localStorage.setItem('idUsuario', this.idUser);
                              this.authService.guardarSesion(data['token'], this.idUser, this.usuarioX.correo, data['user'].nomusuario);
                              this.modal.dismissAll();
                            })
                      }, error => {
                        console.log('error el ingresar3', error);
                      });
                }
              })
        })
      });
  }


  twitterLogin() {

    if (localStorage.getItem('pushToken') == null || localStorage.getItem('pushToken') == undefined || localStorage.getItem('pushToken') == '' || localStorage.getItem('pushToken') == 'null') {
      Swal.fire('LatinosVentas', 'No hemos podido iniciar sesión, por favor intenta nuevamente', 'warning');
      location.reload();
      return;
    }

    return this.auth.setPersistence(firebase.default.auth.Auth.Persistence.LOCAL)
      .then(() => {
        this.auth.signInWithPopup(new firebase.default.auth.TwitterAuthProvider().setCustomParameters({ auth_type: 'reauthenticate' })).then((data) => {
          console.log('signinwithtwitter', data.additionalUserInfo.profile['email']);
          this.paramsX = {
            fb_id: data.user.uid,
            fb_token: data.credential.accessToken,
            uid: '',
            correo: '',
            userId: '',
            firebase_token: localStorage.getItem('pushToken')
          };

          this.usuarioX = {
            correo: data.additionalUserInfo.profile['email'],
            usuarioFb: true,
            usuarioValido: true
          }

          this.api.getResponse('usuarioEmailFB/' + this.usuarioX.correo).pipe(first())
            .subscribe(
              dataUser => {
                console.log('usuarioEmail', dataUser);
                this.paramsX.uid = dataUser._id;
                this.paramsX.correo = dataUser.correo.toString();
                this.paramsX.userId = dataUser._id
                console.log(this.paramsX);
                if (dataUser.usuarioFb) {
                  this.api.postResponse('loginfb/', false, this.paramsX).pipe(first())
                    .subscribe(
                      data => {
                        console.log('loginfb', data);
                        localStorage.setItem('idUsuario', data['user'].userId);
                        this.authService.guardarSesion(data['token'], data['user'].userId, this.paramsX.correo, data['user'].nomusuario);
                        this.modal.dismissAll();
                      })
                }
                else {
                  console.log('entraregistro1', this.usuarioX);
                  this.api.postResponse('registro/', false, this.usuarioX).pipe(first())
                    .subscribe(
                      data => {
                        console.log('registro1', data);
                        this.idUser = data['_id'];
                        this.api.postResponse('loginfb/', false, this.paramsX).pipe(first())
                          .subscribe(
                            data => {
                              console.log('loginfb', data);
                              localStorage.setItem('idUsuario', this.idUser);
                              this.authService.guardarSesion(data['token'], this.idUser, this.paramsX.correo, data['user'].nomusuario);
                              this.modal.dismissAll();
                            })
                      }, error => {
                        console.log(error);
                        this.message = error.error.mensaje;
                      });
                }
              }, error => {
                console.log('error al ingresar', error);
                if (error.error.estatus == 404) {
                  console.log('entraregistro2');
                  this.api.postResponse('registro/', false, this.usuarioX).pipe(first())
                    .subscribe(
                      data => {
                        console.log('registro2', data);
                        console.log('registro2ParamsX', data);
                        this.idUser = data['_id'];
                        this.paramsX.userId = data['_id'];
                        this.api.postResponse('loginfb/', false, this.paramsX).pipe(first())
                          .subscribe(
                            data => {
                              console.log('registro4', data);
                              localStorage.setItem('idUsuario', this.idUser);
                              this.authService.guardarSesion(data['token'], this.idUser, this.usuarioX.correo, data['user'].nomusuario);
                              this.modal.dismissAll();
                            })
                      }, error => {
                        console.log(error);
                      });
                }
              })
        })
      });
  }

  resendEmail: boolean = false;
  idresendEmail: string;
  async loginWithEmail() {
    this.isLoading = true;
    if (this.iniciarForm.valid) {
      // this.message = '';
      this.api.postResponse('login/', false, this.iniciarForm.value).pipe(first())
        .subscribe(
          data => {
            if (data['user'].usuarioValido) {
              this.resendEmail = false;
              localStorage.setItem('idUsuario', data['user']._id);
              this.authService.guardarSesion(data['token'], data['user']._id, data['user'].correo, data['user'].nomusuario);
              this.appC.getCorreo();
              this.router.navigateByUrl('/');
              this.modal.dismissAll();
              this.isLoading = false;
            }
            else {
              if (!data['user'].usuarioValido) {
                this.resendEmail = true;
                this.idresendEmail = data['user']._id;
                this.message = 'Hemos enviado un correo electrónico de validación, para iniciar verifica tu cuenta.';
                this.isLoading = false;
              }
            }
          }, error => {
            this.isLoading = false;
            if (error.error.mensaje)
              this.message = error.error.mensaje;
            else
              this.message = error.error
          })
    }
  }

  reenviarCorreo() {
    this.api.getResponse('ReenviarCorreo/' + this.idresendEmail + '/' + this.iniciarForm.controls['correo'].value, false, this.usuarioX).pipe(first())
      .subscribe(
        data => {
          console.log('resend', data);
          this.toastr.success('LatinosVentas', 'Hemos reenviado un correo a ' + this.iniciarForm.controls['correo'].value);
        }, error => {
          console.log(error);
          this.message = error.error.mensaje;
        });
  }

  recuperar() {
    this.modal.dismissAll();
    this.modal.open(RecuperarComponent);
  }

}

