import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbPanelChangeEvent, NgbAccordion } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-soporte',
  templateUrl: './soporte.component.html',
  styleUrls: ['./soporte.component.css']
})
export class SoporteComponent implements OnInit {


  @ViewChild('myaccordion', { static: true }) accordion: NgbAccordion;

  constructor() { }

  ngOnInit() {
  }

  beforeChange($event: NgbPanelChangeEvent) {

  }

  togglePanel(id) {
    this.accordion.toggle(id);
  }
}
