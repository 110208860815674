import { ApiService } from './../../_services/api.service';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-misanuncios',
  templateUrl: './misanuncios.component.html',
  styleUrls: ['./misanuncios.component.css']
})
export class MisanunciosComponent implements OnInit {

  anuncios: any = [];
  isLoading: Boolean = false;

  constructor(private api: ApiService, private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService, private toastr: ToastrService, private route: Router) {

  }

  ngOnInit(): void {
    this.cargarMisAnuncios();
  }

  cargarMisAnuncios(): void {
    this.isLoading = !this.isLoading;
    this.spinner.show();
    this.api.getResponse('obtenerMisAnuncios/' + localStorage.getItem('idUsuario'), true).pipe(first()).subscribe(data => {

      this.anuncios = data;
      this.spinner.hide();
      this.isLoading = !this.isLoading;
    }, err => {
      this.spinner.hide();
      this.isLoading = !this.isLoading;
    });
  }

  eliminar(id) {
    Swal.fire({
      title: '¿Deseas eliminar este anuncio?',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Aceptar`,
      denyButtonText: `Cancelar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.api.deleteResponse('eliminarAnuncio/' + id, true).pipe(first())
          .subscribe(
            data => {
              // Swal.fire('Latinosventas', '', 'success')
              this.toastr.success('LatinosVentas', 'Se ha eliminado tu anuncio!');
              this.cargarMisAnuncios()
            }, err => {
              console.log(err);
            });

      } else if (result.isDenied) {
        //Swal.fire('No se ha podido eliminar', '', 'info')
        this.toastr.error('LatinosVentas', 'No se ha podido eliminar');
      }
    })
  }

  editar(id) {
    this.route.navigateByUrl('Publicacion/Editar/' + id);
  }

}
