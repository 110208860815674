<div class="modal-header">
  <h4 class="modal-title">Recuperar contraseña</h4>
</div>
<div class="modal-body">
  <form [formGroup]="iniciarForm" (ngSubmit)="this.recuperar()">
    <div>

      <input type="text" class="form-control" formControlName="correo" placeholder="Ingresa tu correo" required>
      <div class="alert alert-danger" *ngIf="iniciarForm.get('correo').invalid  && iniciarForm.get('correo').dirty">
        <div *ngIf="iniciarForm.get('correo').errors.required">
          Ingresa un correo electrónico
        </div>
        <div *ngIf="iniciarForm.get('correo').errors.email">
          Ingresa un correo electrónico válido
        </div>
      </div>
    </div>

    <div class="text-center">
      <div class="alert alert-danger" *ngIf="this.message" style="margin-top: 10px;">
        {{this.message}}
      </div>

      <button type="submit" [disabled]="iniciarForm.invalid" class="btn btn-lt-danger">
        <div *ngIf="isLoading" class="spinner-border btn-spinner" role="status">
        </div>
        <span>Iniciar sesión</span>
      </button>
    </div>
  </form>

</div>
