import { AuthService } from './../../_services/auth.service';
import { environment } from './../../../environments/environment';
import { ApiService } from './../../_services/api.service';
import { first } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { AppComponent } from 'src/app/app.component';
import { DeviceDetectorService } from 'ngx-device-detector';
@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css']
})
export class PerfilComponent implements OnInit {

  perfil: any;

  constructor(private api: ApiService, private activatedRoute: ActivatedRoute, private deviceService: DeviceDetectorService, private app: AppComponent,
    private spinner: NgxSpinnerService, private auth: AuthService) {
    if (this.deviceService.isMobile()) {
      app.activarMobiel(true);
    }
  }

  ngOnInit(): void {
    this.api.getResponse('usuarioEmail/' + localStorage.getItem('correo'), true).pipe(first())
      .subscribe(
        data => {
          console.log('perfil', data);
          this.perfil = data;
        }, err => {
          console.log(err);
        });
  }

  logout() {
    this.auth.logout();
  }

}
