import { first } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from './../_services/api.service';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from '../app.component';
import { DeviceDetectorService } from 'ngx-device-detector';


@Component({
  selector: 'app-anuncios',
  templateUrl: './anuncios.component.html',
  styleUrls: ['./anuncios.component.css']
})
export class AnunciosComponent implements OnInit {
  anuncios: any = [];

  constructor(private api: ApiService, private activatedRoute: ActivatedRoute, private deviceService: DeviceDetectorService,private app: AppComponent,
    private spinner: NgxSpinnerService, private toastr: ToastrService) {
      if(this.deviceService.isMobile()){
        app.activarMobiel(true);
      }
    this.activatedRoute.params.subscribe(params => {
      console.log('activatedRoute', params['subcat']);
      this.cargarAnuncios(params['subcat']);
    });

  }
  ngOnInit(): void {
  }

  cargarAnuncios(subcategoria): void {
    this.spinner.show();
    this.api.getResponse('obtenerAnunciosporCategoria/' + subcategoria + '/' + localStorage.getItem('idUsuario')).pipe(first()).subscribe(data => {
      console.log(data);
      this.anuncios = data;
      this.spinner.hide();
    }, err => { console.log(err), this.spinner.hide() });
  }


  Agregarfavoritos(item) {
    let usuario = {
      usuarioId: localStorage.getItem('idUsuario'),
      anuncioId: item._id
    };

    if (item.tieneFavorito) {
      this.api.deleteResponse('favoritos/' + item._id, false).pipe(first())
        .subscribe(
          data => {
            item.tieneFavorito = !item.tieneFavorito;
            Swal.fire('LatinosVentas', 'Se ha quitado a tus favoritos', 'success');
          }, err => {
          });

    }
    else {
      this.api.postResponse('favoritos', false, usuario).pipe(first())
        .subscribe(
          data => {
            item.tieneFavorito = !item.tieneFavorito;
            Swal.fire('LatinosVentas', 'Se ha agregado a tus favoritos', 'success');
          }, error => {

          })
    }

  }

  agregarFavoritos(item) {
    let usuario = {
      usuarioId: localStorage.getItem('idUsuario'),
      anuncioId: item._id
    };

    console.log(item);

    if (item.tieneFavorito) {
      this.api.deleteResponse('favoritos/' + item._id, true).pipe(first())
        .subscribe(
          data => {
            item.tieneFavorito = !item.tieneFavorito;
            this.toastr.success('LatinosVentas', 'Se ha quitado de tus favoritos');
            //Swal.fire('LatinosVentas','Se ha quitado a tus favoritos','success');
          }, err => {
          });
    }
    else {
      this.api.postResponse('favoritos', true, usuario).pipe(first())
        .subscribe(
          data => {
            item.tieneFavorito = !item.tieneFavorito;
            this.toastr.success('LatinosVentas', 'Se ha agregado a tus favoritos');
            //Swal.fire('LatinosVentas','Se ha agregado a tus favoritos','success');
          }, error => {

          })
    }
  }

}
