<div class="container ">
  <div class="sub-list">
    <h3>Subcategorías</h3><br>
    <div class="row ">
      <div class="col-md-3 col-sm-6 col-12 col-mr" *ngFor="let item of subcategorias">
        <a routerLink="{{abrirProductos(item._id)}}">
          {{item.nombre}}
        </a>
      </div>
    </div>
  </div>
</div>