import { AuthService } from './../../_services/auth.service';
import { first } from 'rxjs/operators';
import { Usuario } from '../../@models/usuario';
import { ApiService } from './../../_services/api.service';

import * as firebase from 'firebase';
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { faMobileAlt, faBell, faSearch, faEnvelope, faMapMarkerAlt, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  isLoading: Boolean = false;
  message: String = '';
  messageSuccess: String = '';

  constructor(public auth: AngularFireAuth, private api: ApiService) {
    this.registerForm = new FormGroup({
      nombre: new FormControl('', [Validators.required]),
      apellido: new FormControl('', [Validators.required]),
      nomusuario: new FormControl('', [Validators.required]),
      telefono: new FormControl('', []),
      correo: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {

  }

  signInWithFB(): void {
    this.auth.signInWithPopup(new firebase.default.auth.FacebookAuthProvider()).then(data => {
      console.log(data);
    }).catch(err => { console.log('errorFB', err) });
  }

  registerWithEmail(): void {
    if (this.registerForm.valid) {
      if (this.registerForm.controls['password'].value != this.registerForm.controls['confirmPassword'].value) {
        this.message = 'Las contraseñas no coinciden';
        return;
      }
      let usuario: Usuario;
      usuario = this.registerForm.value;
      usuario.usuarioFb = false;

      this.api.postResponse('registro/', false, usuario).pipe(first())
        .subscribe(
          data => {
            this.message = '';
            this.messageSuccess = 'Hemos enviado un correo a ' + this.registerForm.controls['correo'].value + ', ingresa a tu cuenta para validarlo';
          }, error => {
            this.messageSuccess = '';
            this.message = error.error.mensaje;
          })
    }
  }
}
