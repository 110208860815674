import { PrivacidadComponent } from './cuenta/privacidad/privacidad.component';
import { TerminosComponent } from './cuenta/terminos/terminos.component';
import { EditaranuncioComponent } from './cuenta/editaranuncio/editaranuncio.component';
import { EditarperfilComponent } from './cuenta/editarperfil/editarperfil.component';
import { RestablecerPasswordComponent } from './cuenta/restablecer-password/restablecer-password.component';
import { RestablecerComponent } from './@modals/restablecer/restablecer.component';
import { BuscaranunciosComponent } from './buscaranuncios/buscaranuncios.component';
import { SoporteComponent } from './soporte/soporte.component';
import { SeleccionarcategoriaComponent } from './publicacion/seleccionarcategoria/seleccionarcategoria.component';
import { AuthguardGuard } from './_services/authguard.guard';
import { FavoritosComponent } from './cuenta/favoritos/favoritos.component';
import { MensajesComponent } from './cuenta/mensajes/mensajes.component';
import { MisanunciosComponent } from './cuenta/misanuncios/misanuncios.component';
import { LoginComponent } from './@modals/login/login.component';
import { PerfilComponent } from './cuenta/perfil/perfil.component';
import { AnunciosComponent } from './anuncios/anuncios.component';
import { SubcategoriasComponent } from './subcategorias/subcategorias.component';
import { DetalleproductoComponent } from './detalleproducto/detalleproducto.component';
import { PrincipalComponent } from './principal/principal.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate  } from '@angular/router';
import { AnunciosusuarioComponent } from './anunciosusuario/anunciosusuario.component';
import { EulaComponent } from './cuenta/eula/eula.component';
import { NegociosComponent } from './directorio/negocios/negocios.component';
import { NuevonegocioComponent } from './directorio/nuevonegocio/nuevonegocio.component';
import { MisnegociosComponent } from './cuenta/misnegocios/misnegocios.component';
import { EditarnegocioComponent } from './directorio/editarnegocio/editarnegocio.component';


const routes: Routes = [
  { path: '', component: PrincipalComponent },
  { path: 'restablecer/:id', component: RestablecerPasswordComponent },
  { path: 'detalle/:id', component: DetalleproductoComponent},
  { path: 'Categoria/:subcat', component: SubcategoriasComponent },
  { path: 'Anuncios/:subcat', component: AnunciosComponent  },
  { path: 'AnunciosUsuario/:user', component: AnunciosusuarioComponent  },
  {
    path: 'Perfil',
    component: PerfilComponent,
    canActivate: [AuthguardGuard],
    children: [
      {path: '', redirectTo: 'MisAnuncios', pathMatch :'full'},
      { path: 'MisAnuncios', component: MisanunciosComponent},
      { path: 'Mensajes', component: MensajesComponent}, 
      { path: 'Favoritos', component: FavoritosComponent},
      { path: 'Editar', component: EditarperfilComponent},
      { path: 'MisNegocios', component: MisnegociosComponent}
    ]
  },
  { path: 'Publicar', component: SeleccionarcategoriaComponent, canActivate : [AuthguardGuard]  },
  { path: 'Publicacion/Editar/:id', component: EditaranuncioComponent, canActivate : [AuthguardGuard] },
  { path: 'soporte', component: SoporteComponent },
  { path: 'buscar/:query', component: BuscaranunciosComponent },
  { path: 'terminos', component: TerminosComponent },
  { path: 'privacidad', component: PrivacidadComponent },
  { path: 'terminosuso', component: EulaComponent },
  { path: 'directorio', component: NegociosComponent},
  { path: 'directorio/:query', component: NegociosComponent },
  { path: 'nuevonegocio', component: NuevonegocioComponent, canActivate : [AuthguardGuard] },
  { path: 'editarnegocio/:id', component: EditarnegocioComponent, canActivate : [AuthguardGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
