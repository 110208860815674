import { ImageCroppedEvent } from 'ngx-image-cropper';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { AuthService } from './../../_services/auth.service';
import { first } from 'rxjs/operators';
import { Usuario } from './../../@models/usuario';
import { ApiService } from './../../_services/api.service';

import * as firebase from 'firebase';
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { faMobileAlt, faBell, faSearch, faEnvelope, faMapMarkerAlt, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/storage';

@Component({
  selector: 'app-editarperfil',
  templateUrl: './editarperfil.component.html',
  styleUrls: ['./editarperfil.component.css']
})
export class EditarperfilComponent implements OnInit {

  imgResultBeforeCompress: string;
  imgResultAfterCompress: string;
  imageChangedEvent: any = '';
  croppedImage: any = '';

  registerForm: FormGroup;
  isLoading: Boolean = false;
  message: String = '';
  messageSuccess: String = '';
  perfil: any;
  imagenPerfil: any;
  constructor(private api: ApiService, private activatedRoute: ActivatedRoute, private imageCompress: NgxImageCompressService,
    private spinner: NgxSpinnerService, private router: Router, private auth: AuthService, private toastr: ToastrService, private storage: AngularFireStorage) {
    this.registerForm = new FormGroup({
      _id: new FormControl('', []),
      nombre: new FormControl('', [Validators.required]),
      apellido: new FormControl('', [Validators.required]),
      nomusuario: new FormControl('', [Validators.required]),
      telefono: new FormControl('', []),
      imagen: new FormControl('', [])
    });
  }

  ngOnInit(): void {
    this.api.getResponse('usuarioId/' + localStorage.getItem('idUsuario')).pipe(first())
      .subscribe(
        data => {
          console.log('perfil', data);
          this.perfil = data;
          this.registerForm.controls['nombre'].setValue(data.nombre);
          this.registerForm.controls['apellido'].setValue(data.apellido);
          this.registerForm.controls['nomusuario'].setValue(data.nomusuario);
          this.registerForm.controls['telefono'].setValue(data.telefono);
          this.imagenPerfil = data.imagen;
        }, err => {
          console.log(err);
        });
  }

  editar() {
    this.onFileSelected(this.croppedImage);
  }

  onFileSelected(image) {
    var n = Date.now();
    const filePath = `users/profile_${new Date().getTime()}.jpg`;
    if (image) {
      const fileRef = this.storage.ref(filePath).putString(image.toString(), 'data_url').then(ok => {
        this.storage.ref(filePath).getDownloadURL().toPromise().then(imgUrl => {
          console.log('imgUle', imgUrl);
          this.registerForm.controls['_id'].setValue(localStorage.getItem('idUsuario'));
          this.registerForm.controls['imagen'].setValue(imgUrl);
          this.api.putResponse('editarUsuario', true, this.registerForm.value).pipe(first())
            .subscribe(
              data => {
                console.log('editarperfil', data);
                this.perfil = data;
                this.toastr.success('LatinosVentas', 'Se ha actualizado tu perfil.');
                setTimeout(function () {
                  window.location.reload();
                }, 100);
              }, err => {
                console.log(err);
                this.toastr.error('LatinosVentas', 'No se ha podido actualizar tu perfil');
              });
        });
      });
    }
    else {
      this.registerForm.controls['_id'].setValue(localStorage.getItem('idUsuario'));
      this.registerForm.controls['imagen'].setValue(this.imagenPerfil);
      this.api.putResponse('editarUsuario', true, this.registerForm.value).pipe(first())
        .subscribe(
          data => {
            console.log('editarperfil', data);
            this.perfil = data;
            this.toastr.success('LatinosVentas', 'Se ha actualizado tu perfil.');
            setTimeout(function () {
              window.location.reload();
            }, 100);

          }, err => {
            console.log(err);
            this.toastr.error('LatinosVentas', 'No se ha podido actualizar tu perfil');
          });
    }
  }

  fileChangeEvent(event: any): void {
    this.imageCompress.uploadFile().then(({ image, orientation }) => {
      this.imgResultBeforeCompress = image;
      console.warn('Size in bytes was:', this.imageCompress.byteCount(image));
      this.imageCompress.compressFile(image, 50, 50).then(
        result => {
          this.imgResultAfterCompress = result;
          this.croppedImage = result;
          console.warn('Size in bytes is now:', this.imageCompress.byteCount(result));
        }
      );

    });
  }

  imageCropped(event: ImageCroppedEvent) {
    console.log(event);
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

}
