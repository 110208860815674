<div class="container-fluid" style="background-color: #fff;">
  <div class="container" style="padding: 60px;background-color: #fff;text-align: justify;">
    <p class="h1 text-center">Términos y condiciones de Uso</p>
    <p class="h3">Introducción</p>
    <p>Al ingresar, utlizar y acceder este sitio y Apps de LATINOSVENTAS, estás aceptando los Términos y condiciones de uso
      contenidos y estipulados en esta sección y declara expresamente su aceptación utilizando para tal efecto medios
      electrónicos, en términos de lo dispuesto en la legislación actual. Además, cuando usted
      utilice cualquier servicio actual o futuro de LATINOSVENTAS también estará sujeto a los lineamientos y condiciones
      aplicables a tal servicio o negocio.
      La utilización de LATINOSVENTAS, Plataforma, Sitio Web, Applicacion, o cualquier otro software o servicio desarollador
      en el futuro le da la condicion de usuario, (en adelante será referido como usuarios o usuarios), mismo que esta
      aceptando por completo todas y cada una de los terminos y condiciones de uso publicados por LATINOSVENTAS. Cualquier
      modificacion podra ser realizada por LATINOSVENTAS cuando lo cosidere apropiado, siendo responsabilidad del usuarios o
      los usuarios asegurarse de tomar conocimiento de las modificaciones.

    </p>
    <p class="h3">Acuerdo</p>
    <p>Acuerdo de adhesión para el uso de LATINOSVENTAS que celebran: por una parte, LATINOSVENTAS y, por la otra, el usuario,
      sujetándose, ambas partes, a lo establecido en este documento.</p>
    <p class="h3">Licencia y Acceso al sitio.</p>
    <p>LATINOSVENTAS le otorga una licencia limitada para acceder y hacer uso personal de este sitio web y no para descargar
      (otra cosa que páginas caché) o modificarlo o cualquier parte del mismo, salvo con el consentimiento expreso por
      escrito de LATINOSVENTAS. Esta licencia no incluye cualquier reventa o uso comercial de este sitio web, Aplicaciones o
      de su contenido; cualquier uso derivado de este sitio web o su contenido; o cualquier uso de la búsqueda de datos,
      robots o métodos similares de recolección o extracción de datos. Este sitio web o cualquier parte de este sitio
      web
      no podrá ser reproducido, duplicado, copiado, vendido, revendido, visitado o de otra manera explotado para
      cualquier
      fin comercial sin el consentimiento expreso por escrito de LATINOSVENTAS. Usted no podrá enmarcar o utilizar técnicas
      de
      enmarcado (framing) para adjuntar cualquier marca comercial, logotipo u otra información propietaria (incluyendo
      imágenes, textos, diseños de página o forma) de LATINOSVENTAS o de sus filiales sin el consentimiento expreso y
      por escrito de LATINOSVENTAS. Usted no podrá utilizar ninguna etiqueta o cualquier otro "texto oculto" que utilice
      el nombre o marcas comerciales de LATINOSVENTAS o de sus filiales sin el consentimiento expreso y por escrito de
      LATINOSVENTAS. Cualquier uso no autorizado da por terminado el permiso o la licencia concedida por LATINOSVENTAS. Se le
      concede un derecho limitado, revocable y no exclusivo para crear un hipervínculo a la página de
      inicio de arc-LATINOSVENTAS.com siempre y cuando el vínculo no represente a LATINOSVENTAS, sus filiales o sus
      productos o en forma falsa, engañosa, despectiva o de otra manera ofensiva. Usted no podrá utilizar ningún
      logotipo
      u otro gráfico propietario o marca comercial de LATINOSVENTAS o de sus filiales como parte del vínculo sin nuestro
      permiso expreso por escrito.El usuario sólo podrá imprimir y/o copiar cualquier información contenida o publicada
      en
      el sitio y Applicaciones de LATINOSVENTAS exclusivamente para uso personal, queda terminantemente prohibido el uso
      comercial de dicha información. En caso de ser persona moral se sujetará a lo dispuesto por el artículo 148,
      fracción IV de la Ley Federal del Derecho de Autor.
    </p>
    <p class="h3">Privacidad</p>
    <p>Favor de revisar nuestro Aviso de Privacidad, que también rige su visita a LATINOSVENTAS, a fin de entender nuestras
      prácticas.</p>
    <p class="h3">Comunicaciones electrónicas</p>
    <p>Al visitar LATINOSVENTAS o enviarnos correos electrónicos, usted se está comunicando con nosotros electrónicamente.
      Usted otorga su consentimiento para recibir comunicaciones electrónicas por parte nuestra. Nosotros nos
      comunicaremos con usted por correo electrónico o mensajeria electronica. Usted acuerda que todos los acuerdos,
      avisos, divulgaciones y otras comunicaciones que le proporcionemos electrónicamente satisfacen cualquier requisito
      legal.</p>
    <p class="h3">Derechos de autor y propiedad Industrial</p>
    <p>Todo el contenido incluido en este sitio web, Aplicaciones y otros servicios de LATINOSVENTAS, como texto, gráficos,
      logotipos, iconos, imágenes, grabaciones de audio, descargas digitales, compilaciones de datos y software, es
      propiedad de LATINOSVENTAS o de sus proveedores de contenido y está protegido por las leyes de los Estados Unidos
      Méxicanos y las leyes internacionales en materia de propiedad intelectual y derechos de autor. La compilación de
      todo el contenido en este sitio web es propiedad exclusiva de LATINOSVENTAS y está protegido por las leyes de los
      Estados Unidos Méxicanos y las leyes internacionales de derechos de autor. Todo el software utilizado en este
      sitio
      web es propiedad de LATINOSVENTAS o de sus proveedores de software y está protegido por las leyes de los Estados
      Méxicanos y las leyes internacionales en materia de propiedad intelectual y derechos de autor.Se prohíbe
      expresamente al usuario modificar, alterar o suprimir, ya sea en forma total o parcial, los avisos, marcas,
      nombres
      comerciales, señas, anuncios, logotipos o en general cualquier indicación que se refiera a la propiedad de la
      información contenida en el sitio señalado.
      <br>
      En caso de que el usuario transmita a LATINOSVENTAS cualquier información, fotografias, videos, audios, programas,
      aplicaciones, software o en general cualquier material que requiera ser licenciado a través de la plataforma
      LATINOSVENTAS, el usuario otorga con este acto a LATINOSVENTAS una licencia perpetua, universal, gratuita, no exclusiva,
      mundial y libre de regalías, que incluye los derechos de sublicenciar, vender, reproducir, distribuir, transmitir,
      crear trabajos derivados, exhibirlos y ejecutarlos públicamente.
      <br>
      Lo establecido en el párrafo anterior se aplicará igualmente a cualquier otra información que el usuario envíe o
      transmita a LATINOSVENTAS, incluyendo, sin limitación alguna, ideas para renovar o mejorar el sitio web, plataforma, o
      aplicaciones de LATINOSVENTAS, ya sea que éstas hayan sido incluidas o que sean desarrollados en el futuro.
      Por lo anterior, el usuario renuncia expresamente con este acto a llevar a cabo cualquier acción, demanda o
      reclamación en contra de LATINOSVENTAS, sus afiliados o proveedores por cualquier actual o eventual violación de
      cualquier derecho de autor o propiedad intelectual derivado de la información, programas, aplicaciones, software,
      ideas y demás material que el propio usuario envíe a la plataforma, sitio web o aplicaciones de LATINOSVENTAS.
      Es nuestra política actuar contra las violaciones que en materia de propiedad intelectual se pudieran generar
      según
      lo estipulado en la legislación y en otras leyes de propiedad intelectual aplicables, incluyendo la eliminación o
      el
      bloqueo del acceso a material que se encuentra sujeto a actividades que infrinjan el derecho de propiedad
      intelectual de terceros. En caso de que algún usuario o tercero consideren que cualquiera de los contenidos que se
      encuentren o sean introducidos en la plataforma, sitio web, aplicaciones y cualquiera de los servicios de
      LATINOSVENTAS,
      violen sus derechos de propiedad intelectual deberán enviar una notificación a la siguiente dirección
      hola@arc-LATINOSVENTAS.com, en la que indiquen: i) datos personales verídicos (nombre, dirección, número de teléfono y
      dirección de correo electrónico del reclamante); ii) firma autógrafa con los datos personales del titular de los
      derechos de propiedad intelectual; iii) indicación precisa y completa del (los) contenido (s) protegido (s)
      mediante
      los derechos de propiedad intelectual supuestamente infringidos, así como la localización de dichas violaciones en
      el sitio web referido; iv) declaración expresa y clara de que la introducción del (los) contenido (s) indicado (s)
      se ha realizado sin el consentimiento del titular de los derechos de propiedad intelectual supuestamente
      infringidos; v) declaración expresa, clara y bajo la responsabilidad del reclamante de que la información
      proporcionada en la notificación es exacta y de que la introducción del (los) contenido (s) constituye una
      violación
      de dichos derechos.

    </p>
    <p class="h3">Reglas para el uso del sitio web LATINOSVENTAS</p>
    <p>El usuario esta de acuerdo que al utilizar LATINOSVENTAS se sujetará a las siguientes reglas:

      La información, conceptos y opiniones publicadas en dicho sitio no necesariamente reflejan la posición de
      LATINOSVENTAS,
      ni de sus empleados, oficiales, directores, accionistas, licenciatarios y concesionarios (en lo sucesivo los
      «afiliados»). Por esta razón, LATINOSVENTAS no se hace responsable por ninguna de las informaciones, opiniones y
      conceptos que se emitan en la página web referida. En este caso, se recomienda al usuario consultar con un
      especialista y/o profesional en la materia. Asimismo, LATINOSVENTAS no se hace responsable de la información contenida
      en la página de Internet, plataforma y aplicaciones, incluidas las subpáginas, en el entendido de que el uso y
      seguimiento de la misma es bajo riesgo y responsabilidad del usuario.
      LATINOSVENTAS se reserva el derecho de bloquear el acceso o remover en forma parcial o total toda información,
      comunicación o material que a su exclusivo juicio pueda resultar abusivo, difamatorio u obsceno; fraudulento,
      artificioso o engañoso; violatorio de derechos de autor, marcas, confidencialidad, secretos industriales o
      cualquier
      derecho de propiedad intelectual de un tercero; ofensivo o; que de cualquier forma contravenga lo establecido en
      este convenio. Si el usuario desea obtener mayor información de un tema en específico proveído por LATINOSVENTAS o sus
      afiliados deberá consultarlo directamente con cada uno de ellos, según corresponda, y/o con un especialista en la
      materia.

      El usuario reconoce que LATINOSVENTAS no controla o censura previamente el contenido disponible en la plataforma,
      sitio
      web, aplicaciones y otros sercicios. Por tal motivo, LATINOSVENTAS no asume ninguna responsabilidad por el contenido
      provisto a dicha página por proveedores independientes o ajenos y no tiene el control editorial sobre el
      contenido,
      información y/o material generado y/o provisto por terceros. Todas las opiniones, consejos, declaraciones,
      servicios, ofertas u otras informaciones o contenidos expresados o puestos a disposición del público por terceros,
      pertenecen a su respectivo autor y LATINOSVENTAS no asume responsabilidad alguna frente a ello. De la misma forma,
      LATINOSVENTAS no garantiza la exactitud, veracidad, amplitud y/o utilidad de cualquier contenido provisto por tales
      terceros. Adicionalmente, LATINOSVENTAS no es responsable ni garantiza la exactitud, exhaustividad, veracidad y/o
      confiabilidad de cualquier opinión, información, consejo o declaración expresados a través de su página de
      Internet,
      plataforma, aplicativos y/o servicios; Bajo ninguna circunstancia LATINOSVENTAS será responsable de cualquier daño y/o
      perjuicio, directo o indirecto, causado en virtud de la confianza que el usuario deposite en información obtenida
      a
      través de su sitio web. LATINOSVENTAS se reserva el derecho de suprimir o modificar el contenido de esta página que, a
      exclusivo juicio de LATINOSVENTAS no cumpla con sus estándares o que pudiera resultar contrario al ordenamiento
      jurídico
      vigente y, por consiguiente, no será responsable por cualquier falla o tardanza que se genere al eliminar tal
      material.
    </p>
    <p class="h3">Su actividad en LATINOSVENTAS</p>
    <p>LATINOSVENTAS no está diseñado para ser utilizado por niños. Si usted es menor de 18 años, usted podrá utilizar los
      servicios de LATINOSVENTAS únicamente con la participación de un padre o tutor. LATINOSVENTAS se reservan el derecho a
      negarse a prestar el servicio, cerrar cuentas o eliminar o editar contenido a su entera discreción.</p>
    <p class="h3">Material publicitario</p>
    <p>El usuario reconoce y acepta que LATINOSVENTAS es una organización independiente de terceros patrocinadores,
      afiliados
      y anunciantes cuya información, imágenes, anuncios y demás material publicitario o promocional puede ser publicado
      en la plataforma, sitio web, aplicaciones y servicios de LATINOSVENTAS.
      El usuario reconoce y acepta que el material publicitario no forma parte del contenido principal que se publica en
      dicho sitio. Asimismo, reconoce y acepta con este acto que este material se encuentra protegido por las leyes que
      en
      materia de propiedad intelectual e industrial resulten aplicables.
    </p>
    <p class="h3">Exclusión de garantías y limitación de la responsabilidad</p>
    <p>Este sitio web, plataforma, aplicaciones son proporcionado por LATINOSVENTAS "tal como está" y "según
      disponibilidad".
      LATINOSVENTAS no manifiesta ninguna representación o garantía de ningún tipo, expresa o implícita, en relación con la
      operación de este sitio web, plataforma y aplicaciones o la información, contenido, materiales o productos
      incluidos. El usuario acepta expresamente que el uso de esta plataforma, sitio web, aplicaciones y servicios
      provehidos por LATINOSVENTAS es bajo su propio riesgo. LATINOSVENTAS se reserva el derecho a remover o eliminar cualquier
      información de este sitio web, en cualquier momento, a su entera discreción. En la medida permitida por la ley
      aplicable, LATINOSVENTAS renuncia a toda garantía, expresa o implícita, incluyendo, sin limitación, las garantías
      implícitas de comercialización e idoneidad para algún propósito en particular. LATINOSVENTAS no garantiza que este
      plataforma, sitio web, aplicaciones o servicios, sus servidores o el correo electrónico enviado por LATINOSVENTAS
      están
      libres de virus o de cualquier otro elemento perjudicial. LATINOSVENTAS no será responsable por ningún daño o
      perjuicio,
      de ningún tipo, que derive del uso de este sitio web, incluyendo, sin limitación, daños y perjuicios directos,
      indirectos, incidentales, punitivos y consecuenciales. El usuario está de acuerdo que la utilización de la
      plataforma, sitio web, aplicaciones y servicios de LATINOSVENTASse realiza bajo su propio riesgo y que los servicios y
      productos que ahí se prestan y ofrecen se prevén sobre una base «tal cual» y «según sean disponibles». LATINOSVENTAS
      no
      garantiza que la página señalada satisfaga los requerimientos del usuario o que los servicios que en ella se
      ofrecen
      no sufran interrupciones, sean seguros o estén exentos de errores. LATINOSVENTAS se libera de cualquier
      responsabilidad
      y condiciones, si la disponibilidad de uso de la plataforma, sitio web, aplicaciones y servicios LATINOSVENTAS se ve
      interrumpida total o parcilamente. LATINOSVENTAS y sus afilidados podrán actualizar el contenido de la página
      constantemente, por lo que se solicita al usuario tomar en cuenta que algunas informaciones publicitadas o
      contenidas en o a través de este sitio web pueden haber quedado obsoletas y/o contener imprecisiones o errores
      tipográficos u ortográficos.</p>
    <p class="h3">Modificaciones a la plataforma, sitio web, aplicaciones y servicios LATINOSVENTAS</p>
    <p>SPOTLIIGHT podrá en cualquier momento y cuando lo considere conveniente, sin necesidad de avisar al usuario,
      realizar correcciones, adiciones, mejoras o modificaciones al contenido, presentación, información, servicios,
      áreas, bases de datos y demás elementos en la plataforma, sitio web, aplicaciones y servicios ofrecidos por
      LATINOSVENTAS, sin que ello de lugar ni derecho a ninguna reclamación o indemnización, ni que esto implique
      reconocimiento de responsabilidad alguna a favor del usuario.</p>
    <p class="h3">Modificaciones al convenio</p>
    <p>LATINOSVENTAS se reserva el derecho de modificar los Términos y condiciones de uso de este convenio en cualquier
      momento, siendo efectivas dichas modificaciones de forma inmediata por medio de este apartado, el usuario está de
      acuerdo en revisar dicho convenio periódicamente con la finalidad de mantenerse al tanto de dichas modificaciones.
      No obstante lo anterior, cada vez que el usuario acceda al sitio señalado se considerará como una aceptación
      absoluta a las modificaciones del presente convenio.</p>
    <p class="h3">Indemnización</p>
    <p>El usuario está de acuerdo en indemnizar a LATINOSVENTAS, sus afiliados, proveedores, vendedores y asesores por
      cualquier acción, demanda o reclamación (incluso de honorarios de abogados y de costas judiciales) derivadas de
      cualquier incumplimiento por parte del usuario al presente convenio; incluyendo, sin limitación de alguna de las
      derivadas de:
      • Cualquier aspecto relativo al uso de la plataforma, sitio web, aplicaciones y servicios que ofrece LATINOSVENTAS.
      • La información contenida o disponible en o a través de dicha plataforma, sitio web, aplicaciones y servicios que
      LATINOSVENTAS ofrece sea objeto de injurias, difamación o cualquier otra conducta violatoria del presente convenio por
      parte del usuario con LATINOSVENTAS
      • La violación a las leyes aplicables o tratados internacionales relativos a los derechos de autor o propiedad
      intelectual, contenidos o disponibles en, o a través de la plataforma, sitio web, aplicaciones y servicios que
      LATINOSVENTAS ofrece.

    </p>
    <p class="h3">Terminación</p>
    <p>LATINOSVENTAS se reserva el derecho sin necesidad de aviso o notificación al usuario, para Terminar definitivamente
      el
      presente convenio, Descontinuar o dejar de publicar definitivamente el sitio web, aplicaciones y otros servicios
      de
      LATINOSVENTAS sin responsabilidad alguna para LATINOSVENTAS, sus afiliados o proveedores.</p>
    <p class="h3">Subsistencia</p>
    <p>Estos Términos y condiciones de uso, así como los términos adicionales indicados en esta sección sustituye
      cualquier otro acuerdo o convenio celebrado con anterioridad. Este convenio podrá modificarse a elección de
      LATINOSVENTAS, Sin embargo, las cláusulas estipuladas en esta sección mantendrán su obligatoriedad y validez.</p>
    <p class="h3">No renuncia de derechos</p>
    <p>La inactividad por parte de LATINOSVENTAS, en ningún momento deberá interpretarse como renuncia a dichos derechos o
      acciones.</p>
    <p class="h3">Legislación aplicable y jurisdicción</p>
    <p>Este convenio estará sujeto y será interpretado de acuerdo con las leyes y ante los tribunales de la Ciudad de
      Guadalajara, Jalisco, México.</p>



  </div>
</div>
