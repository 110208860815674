import { first } from 'rxjs/operators';
import { ApiService } from './api.service';
import { MessagingService } from './messaging.service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loggedIn = new BehaviorSubject<boolean>(false);

  constructor(private router: Router,public jwtHelper:JwtHelperService,private messagingService: MessagingService,
    private api:ApiService) {
    if(this.getAutenticatedUser()){
      this.loggedIn.next(true);
    }else{
      this.loggedIn.next(false);
    }
   }

  validarSesion(): void {
    if (localStorage.getItem('token') == undefined) {
      this.router.navigate(['/login']);
    }
  }

  guardarSesion(token: string, usuario: string, correo: string,username:string): Boolean {
    localStorage.setItem('token', token);
    localStorage.setItem('_id', usuario);
    localStorage.setItem('correo', correo);
    localStorage.setItem('username', username);
    this.loggedIn.next(true);
    return true;
  }

  cerrarSesion(): Boolean {
    localStorage.clear();
    return true;
  }

  getAutenticatedUser(): String {
    return  localStorage.getItem('token');
  }

  getAutenticatedEmail(): String {
    var email = localStorage.getItem('username');
    if(email != 'undefined'){
      return email;
    }
    else{
    return  localStorage.getItem('correo');
    }
  }


  get isLoggedIn() {
    return this.loggedIn.asObservable(); // {2}
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    return !this.jwtHelper.isTokenExpired(token);
  }

  logout() {    
    let params ={
      userId: localStorage.getItem('idUsuario'),
      firebase_token:localStorage.getItem('pushToken')
    }
    this.api.postResponse('logout', false, params).pipe(first())
    .subscribe(
      data => {
        this.loggedIn.next(false);
        localStorage.clear();
        this.messagingService.requestPermission();
        this.router.navigate(['']);
      }, err => {
        this.loggedIn.next(false);
        localStorage.clear();
        this.router.navigate(['']);
      });                      
  }

}
