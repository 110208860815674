import { AuthService } from './../_services/auth.service';
import { RestablecerComponent } from './../@modals/restablecer/restablecer.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from './../_services/api.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from './../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from '../app.component';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.css']
})
export class PrincipalComponent implements OnInit {

  customOptions: OwlOptions = {
    
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: ['<', '>'],
    autoWidth:true,
    autoplay:true,
    autoplayTimeout:1000,
    autoplaySpeed:1000,
    autoplayHoverPause:false,
    loop:false,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 8
      },
      740: {
        items: 4
      },
      940: {
        items: 8
      },
      1024: {
        items: 8
      },
      1920: {
        items: 8
      }
    },
    nav: true
  }
  anuncios: any = [];
  loading: boolean = false;
  sinResultados: boolean = false;
  textoBusqueda: string = '';
  categorias: any = [];
  negocios:any  = [];

  constructor(private api: ApiService, private router: Router, private spinner: NgxSpinnerService, private deviceService: DeviceDetectorService,private app: AppComponent,
    private toastr: ToastrService, private activatedRoute: ActivatedRoute, private modal: NgbModal, private auth: AuthService) {
      if(this.deviceService.isMobile()){
        app.activarMobiel(true);
      }
     }

  ngOnInit(): void {

    this.cargarAnuncios(null);
    this.obtenerNegocios(0, 0, 'undefined');
    this.cargarCategorias();
  }

  obtenerNegocios(ciudadId,catId,busqueda?){
    this.api.getResponse('negociosPrincipal', true).pipe(first()).subscribe(data => {
      console.log('negocios',data);
      this.negocios = data;
      this.negocios.forEach(element => {
        if(!element.merge){
          element.merge = 300;
        }
      });
      this.spinner.hide();
    }, err => { console.log(err), this.spinner.hide() });
  }

  cargarCategorias() {
    this.api.getResponse('categorias').pipe(first()).subscribe(data => {
      this.categorias = data;
      console.log('categorias',data);
    }, err => { console.log(err) });
  }


  async cargarAnuncios(event) {
    console.log('cargando anuncios');
    this.textoBusqueda = '';
    this.loading = true;
    // this.spinner.show('spinner-popular');
    var busqueda = localStorage.getItem('busqueda');
    if (busqueda == '' || busqueda == null || busqueda == undefined) {
      console.log('cargando anuncios 1');
      this.api.getResponse('anuncios/' + localStorage.getItem('idUsuario'), false).pipe(first())
        .subscribe(
          data => {
            console.log('anuncios', data);
            this.loading = false;
            this.spinner.hide('spinner-popular');
            this.anuncios = data;
            if (this.anuncios.length <= 0)
              this.sinResultados = true
            else
              this.sinResultados = false;
            if (event != null)
              event.target.complete();

          }, err => {
            this.loading = false;
            this.spinner.hide('spinner-popular');
            this.sinResultados = true
            event.target.complete();
          });
    }
    else {
      this.textoBusqueda = busqueda;
      console.log('cargando anuncios2');
      this.api.getResponse('buscarAnuncios/' + localStorage.getItem('busqueda'), false).pipe(first())
        .subscribe(
          data => {
            this.loading = false;
            this.spinner.hide('spinner-popular');
            this.anuncios = data;
            if (this.anuncios.length <= 0)
              this.sinResultados = true
            else
              this.sinResultados = false;
            if (event != null)
              event.target.complete();
          }, err => {
            this.loading = false;
            this.spinner.hide('spinner-popular');
            this.sinResultados = true
            event.target.complete();
          });
    }

  }

  cargarAnunciosCat(id){
    return this.anuncios.filter(x=>x.categoriaId == id).slice(0,4);
  }


  agregarFavoritos(item) {
    let usuario = {
      usuarioId: localStorage.getItem('idUsuario'),
      anuncioId: item._id
    };

    console.log(item);

    if (item.tieneFavorito) {
      this.api.deleteResponse('favoritos/' + item._id, true).pipe(first())
        .subscribe(
          data => {
            item.tieneFavorito = !item.tieneFavorito;
            this.toastr.success('LatinosVentas', 'Se ha quitado de tus favoritos');
            //Swal.fire('LatinosVentas','Se ha quitado a tus favoritos','success');
          }, err => {
          });
    }
    else {
      this.api.postResponse('favoritos', true, usuario).pipe(first())
        .subscribe(
          data => {
            item.tieneFavorito = !item.tieneFavorito;
            this.toastr.success('LatinosVentas', 'Se ha agregado a tus favoritos');
            //Swal.fire('LatinosVentas','Se ha agregado a tus favoritos','success');
          }, error => {

          })
    }
  }

  abrirProductos(id): string {
    return '/Anuncios/' + id;
  }

}
