<div class="container" style="padding: 30px;">
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">T&eacute;rminos
            de uso Latinos Ventas</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Para
            : Web, iOS y Android</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Bienvenido
            a www.latinosventas.ca. Estos T&eacute;rminos de uso, la Pol&iacute;tica de privacidad, los T&eacute;rminos
            de dispositivos m&oacute;viles y todas las pol&iacute;ticas publicadas en nuestro sitio establecen los
            t&eacute;rminos en los que le ofrecemos acceso y uso de nuestro sitio, servicios, aplicaciones y
            herramientas (Servicios). Usted acepta cumplir con todo lo anterior al acceder o utilizar nuestros
            Servicios.</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Latinos
            Ventas, 8810 ROYAL BIRCH BLVD NW CALGARY ALBERTA CANADA T3G6A9</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Su
            cuenta.</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Para
            acceder y utilizar algunos o una parte de los Servicios, es posible que deba registrarse con nosotros y
            configurar una cuenta con su direcci&oacute;n de correo electr&oacute;nico y una contrase&ntilde;a (Cuenta).
            La direcci&oacute;n de correo electr&oacute;nico que proporcione ser&aacute; su direcci&oacute;n de correo
            electr&oacute;nico, y usted es el &uacute;nico responsable de mantener la confidencialidad de su
            contrase&ntilde;a. Usted es el &uacute;nico responsable de todas las actividades que se realicen en su
            Cuenta. Por lo tanto, debe proteger su contrase&ntilde;a y hacer que sea dif&iacute;cil de adivinar para
            otros. No transferir&aacute; su cuenta a otra parte sin nuestro consentimiento.</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Puede
            conectarse a los Servicios con un servicio de terceros y nos da permiso para acceder, almacenar y utilizar
            su informaci&oacute;n de ese servicio seg&uacute;n lo permitido por ese servicio y como se describe en
            nuestra Pol&iacute;tica de privacidad. Si cree que su cuenta puede haber sido comprometida o mal utilizada,
            cont&aacute;ctenos inmediatamente a Atenci&oacute;n al cliente de Latinosventas al correo&nbsp;</span><a
            href="mailto:soporte@latinosventas.com" style="text-decoration:none;"><span
                style="font-size:11pt;font-family:Arial;color:#1155cc;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">soporte@latinosventas.com</span></a><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span>
    </p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Uso
            de los servicios.</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Publicar&aacute;n
            en la categor&iacute;a o &aacute;rea apropiada y no har&aacute;s ninguna de las siguientes cosas
            malas:</span></p>
    <p><br></p>
    <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Violar
                    cualquier ley o nuestra Pol&iacute;tica de contenido prohibido.</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Ser
                    falso o enga&ntilde;oso.</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Infringir
                    cualquier derecho de terceros.</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Distribuir
                    o contener spam, cartas en cadena o esquemas piramidales.</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Distribuir
                    virus o cualquier otra tecnolog&iacute;a que pueda da&ntilde;ar a LatinosVentas o los intereses o la
                    propiedad de los usuarios de LatinosVentas.</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Imponer
                    una carga irrazonable a nuestra infraestructura o interferir con el correcto funcionamiento del
                    LatinosVentas.</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Copiar,
                    modificar o distribuir el contenido de cualquier otra persona.</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Utilizar
                    cualquier robot, ara&ntilde;a, raspador u otro medio automatizado para acceder a LatinosVentas y
                    recopilar contenido para cualquier prop&oacute;sito sin nuestro permiso expreso por escrito.</span>
            </p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Recolectar
                    o recopilar informaci&oacute;n sobre otros, incluidas las direcciones de correo electr&oacute;nico,
                    sin su consentimiento.</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Eludir
                    las medidas utilizadas para prevenir o restringir el acceso a LatinosVentas.</span></p>
        </li>
    </ul>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Abusar
            de los servicios de LatinosVentas.&nbsp;</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Utilice
            el sistema de reporte para informarnos sobre cualquier problema o contenido ofensivo para que juntos podamos
            mantener el sitio de Servicios funcionando correctamente. Podemos limitar o cancelar nuestros Servicios,
            eliminar el contenido alojado y tomar medidas t&eacute;cnicas y legales para mantener a los usuarios fuera
            de LatinosVentas si creemos que est&aacute;n creando problemas o actuando de manera inconsistente con la
            letra o el esp&iacute;ritu de nuestras pol&iacute;ticas. Sin embargo, ya sea que decidamos tomar cualquiera
            de estos pasos, eliminar el contenido alojado o mantener a un usuario fuera de LatinosVentas o no, no
            aceptamos ninguna responsabilidad por monitorear LatinosVentas o por contenido no autorizado o ilegal en
            LatinosVentas o el uso de LatinosVentas por parte de los usuarios.</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Mercado
            global.&nbsp;</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Sin
            limitaci&oacute;n a los derechos otorgados a continuaci&oacute;n (Contenido), algunas de las funciones de
            LatinosVentas pueden mostrar su anuncio en otros sitios que forman parte de nuestros sitios de anuncios
            clasificados en otros pa&iacute;ses. Al utilizar los Servicios, acepta que sus anuncios se puedan mostrar en
            estos otros sitios. Los t&eacute;rminos para nuestros otros sitios son similares a estos t&eacute;rminos,
            pero puede estar sujeto a leyes adicionales u otras restricciones en los pa&iacute;ses donde se publica su
            anuncio. Cuando elige publicar su anuncio en otro sitio, puede ser responsable de asegurarse de que no
            infrinja nuestras pol&iacute;ticas de otros sitios. Podemos eliminar su anuncio si est&aacute; marcado en
            cualquiera de nuestros sitios, o si creemos que causa problemas o viola alguna ley o pol&iacute;tica.</span>
    </p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Tarifas
            y servicios.</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El
            uso de los Servicios es generalmente gratuito. Podemos cobrar tarifas por ciertos Servicios. Si el servicio
            que utiliza cobra una tarifa, podr&aacute; revisar y aceptar ese cargo. Nuestras tarifas se cotizan en
            d&oacute;lares canadienses y podemos cambiarlas de vez en cuando. Le notificaremos los cambios en nuestra
            pol&iacute;tica de tarifas publicando dichos cambios en el sitio. Podemos optar por cambiar temporalmente
            nuestras tarifas para eventos promocionales o nuevos servicios; Estos cambios entran en vigencia cuando
            anunciamos el evento promocional o el nuevo servicio. Nuestras tarifas no son reembolsables y usted es
            responsable de pagarlas cuando se vencen. Si no lo hace, podemos limitar su capacidad para utilizar los
            Servicios. Si su m&eacute;todo de pago falla o su cuenta est&aacute; vencida, podemos cobrar las tarifas
            adeudadas utilizando otros mecanismos de cobro.</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Los
            impuestos canadienses asociados con nuestros Servicios se cobrar&aacute;n cuando corresponda. Usted acepta
            proporcionar la informaci&oacute;n de direcci&oacute;n precisa necesaria para que LatinosVentas cumpla con
            sus obligaciones seg&uacute;n la ley aplicable. En la medida en que no se proporcione dicha
            informaci&oacute;n, LatinosVentas, a su discreci&oacute;n, determinar&aacute; y recaudar&aacute; los
            impuestos correspondientes.</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Usted
            es el &uacute;nico responsable de cobrar y remitir los impuestos aplicables que resulten de la venta de sus
            art&iacute;culos o servicios enumerados en LatinosVentas.</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Si
            bien muchas caracter&iacute;sticas de los Servicios est&aacute;n disponibles en los sitios web de
            LatinosVentas y sus aplicaciones m&oacute;viles asociadas, algunas caracter&iacute;sticas y funcionalidades
            pueden variar seg&uacute;n el sitio web y / o el dispositivo m&oacute;vil que utilice para acceder a los
            Servicios.</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Contenido.</span>
    </p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Los
            Servicios contienen informaci&oacute;n nuestra, de usted y de otros usuarios (as&iacute; como de nuestros
            proveedores de servicios externos). Acepta no copiar, modificar, revender o distribuir los Servicios,
            nuestros derechos de autor o marcas comerciales. Cuando nos proporciona contenido, nos otorga un derecho no
            exclusivo, mundial, perpetuo, irrevocable, libre de regal&iacute;as y sublicenciable para ejercer los
            derechos de autor, publicidad y base de datos sobre ese contenido. Si cree que se han violado sus derechos,
            notifique al servicio de atenci&oacute;n al cliente de LatinosVentas e investigaremos el asunto. Nos
            reservamos el derecho de eliminar contenido cuando tengamos motivos para sospechar la violaci&oacute;n de
            estos t&eacute;rminos o los derechos de cualquier otra parte.</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Pol&iacute;ticas
            de publicaci&oacute;n</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Las
            siguientes actividades est&aacute;n prohibidas en LatinosVentas Canad&aacute;:</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
    </p>
    <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Publicar
                    un art&iacute;culo para la venta que no se encuentra en Canad&aacute;.</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Publicar
                    un art&iacute;culo para la venta que es ilegal poseer, comprar o vender en su &aacute;rea de
                    residencia.</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Publicar
                    un art&iacute;culo para la venta que est&aacute; en la lista de art&iacute;culos y servicios
                    prohibidos.</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Publicar
                    anuncios duplicados en varias categor&iacute;as.</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Publicar
                    anuncios utilizando varias direcciones de correo electr&oacute;nico diferentes.</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Publicar
                    un anuncio que no tenga otro prop&oacute;sito que el de enviar tr&aacute;fico a un sitio web.</span>
            </p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Publicar
                    un anuncio que infrinja el C&oacute;digo de conducta laboral de LatinosVentas.</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Publicar
                    un anuncio que incluya contenido er&oacute;tico, para adultos o para adultos. No se permiten
                    personales.</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Publicar
                    un anuncio que difama a alguien o contiene &quot;incitaci&oacute;n al odio&quot;.</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Publicar
                    opiniones, avisos y temas de discusi&oacute;n que, en su lugar, deber&iacute;an publicarse en los
                    sitios de redes sociales.</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Publicar
                    un anuncio en una categor&iacute;a incorrecta o enga&ntilde;osa o con informaci&oacute;n
                    enga&ntilde;osa.</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Publicar
                    un anuncio que infrinja las pol&iacute;ticas de publicaci&oacute;n Anuncios prohibidos</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Publicar
                    un anuncio que viole la pol&iacute;tica de igualdad en la vivienda de LatinosVentas.</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Publicar
                    como vendedor privado, propietario o individuo cuando sea una empresa, un distribuidor o un
                    profesional.</span></p>
        </li>
    </ul>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
    </p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Tenga
            en cuenta:</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Los
            usuarios deben tener al menos 16 a&ntilde;os para publicar anuncios por su cuenta. Los anuncios para
            nuestros usuarios m&aacute;s j&oacute;venes deben ser publicados por un adulto por razones de
            seguridad.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Cualquier
            anuncio que no cumpla con las pol&iacute;ticas de LatinosVentas puede ser eliminado del sitio.
            Adem&aacute;s, es posible que los usuarios que violen dichas pol&iacute;ticas no puedan usar LatinosVentas
            en el futuro.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Podemos,
            en cualquier momento, eliminar un anuncio si creemos que interfiere con el bienestar de la comunidad o el
            esp&iacute;ritu de LatinosVentas.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Nuestra
            regla general es que no puede enumerar, comprar o vender art&iacute;culos o servicios que sean ilegales de
            poseer, comprar o vender en su provincia de residencia, o que no se compren o vendan de acuerdo con las
            leyes y regulaciones aplicables. Usted es responsable de garantizar el cumplimiento de todas las leyes
            aplicables relacionadas con el art&iacute;culo que enumera, compra o vende.</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Contenido
            prohibido</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">La
            siguiente lista detalla lo que no se puede publicar en LatinosVentas:</span></p>
    <p><br></p>
    <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Adulto</span>
            </p>
        </li>
    </ul>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Incluyendo pornograf&iacute;a, fotos de desnudos, videos, art&iacute;culos o productos
            er&oacute;ticos.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Incluidos servicios de prostituci&oacute;n, escolta o acompa&ntilde;amiento</span></p>
    <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Alcohol</span>
            </p>
        </li>
    </ul>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Incluida la entrega de alcohol y bebidas relacionadas.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Nota: Se permite vender botellas, etiquetas o tapas vac&iacute;as como objetos de colecci&oacute;n.</span>
    </p>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Nota: La contrataci&oacute;n de personal como bartender, recepci&oacute;n o vendedor est&aacute; permitida
            en la categor&iacute;a Trabajos.</span></p>
    <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Art&iacute;culos
                    para beb&eacute;s considerados inseguros por Health Canada</span></p>
        </li>
    </ul>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Nidos de beb&eacute;</span></p>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Andadores de beb&eacute;</span></p>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Asientos para autom&oacute;vil y asientos elevados fabricados antes del 1 de enero de 2012</span></p>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Traviesas inclinadas (retiradas del mercado o no) o productos que podr&iacute;an soportar durmientes
            inclinados</span></p>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Cunas tradicionales abatibles</span></p>
    <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Sangre,
                    fluidos corporales y partes del cuerpo</span></p>
        </li>
    </ul>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Incluidos &oacute;rganos</span></p>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Incluida la subrogaci&oacute;n, la fertilidad y las solicitudes de donantes (incluido el esperma)</span></p>
    <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Herramientas
                    de robo</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Cannabis
                    y productos a base de cannabis</span></p>
        </li>
    </ul>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Incluidos extractos, comestibles y aceites.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Incluidos narguiles, pipas, bongs y vaporizadores</span></p>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Art&iacute;culos de promoci&oacute;n / recuerdos de cannabis</span></p>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Haga clic aqu&iacute; para obtener m&aacute;s detalles sobre nuestras pol&iacute;ticas de productos de
            cannabis</span></p>
    <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Servicios
                    de adopci&oacute;n de ni&ntilde;os (b&uacute;squeda y oferta)</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Art&iacute;culos
                    combustibles (incluidas bengalas)</span></p>
        </li>
    </ul>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Nota: Las bengalas de emergencia y socorro est&aacute;n permitidas s&oacute;lo si est&aacute;n
            dise&ntilde;adas y destinadas a fines de se&ntilde;alizaci&oacute;n.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Nota: No hay ofertas de env&iacute;o.</span></p>
    <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Art&iacute;culos
                    relacionados con COVID-19</span></p>
        </li>
    </ul>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Pruebas r&aacute;pidas de ant&iacute;geno COVID-19</span></p>
    <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Art&iacute;culos
                    falsificados y distribuci&oacute;n de material protegido por derechos de autor</span></p>
        </li>
    </ul>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Incluyendo moneda, sellos y monedas.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Incluidos libros electr&oacute;nicos</span></p>
    <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Art&iacute;culos
                    o cuentas digitales</span></p>
        </li>
    </ul>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Incluidas cuentas de juegos, cuentas con asignaciones para m&uacute;ltiples dispositivos y copias digitales
            de pel&iacute;culas, libros o software.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Haga clic aqu&iacute; para obtener m&aacute;s detalles sobre nuestra pol&iacute;tica de cuentas y
            art&iacute;culos digitales</span></p>
    <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Bienes
                    embargados</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Entradas
                    para eventos</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Identificaciones,
                    distintivos, uniformes y licencias emitidos por el gobierno</span></p>
        </li>
    </ul>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Incluidos los de tr&aacute;nsito, militares, policiales, postales, etc.</span></p>
    <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Materiales
                    peligrosos</span></p>
        </li>
    </ul>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Incluyendo productos qu&iacute;micos, productos de control de plagas restringidos, suspendidos o cancelados
            y fuegos artificiales.</span></p>
    <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Punteros
                    l&aacute;ser de alta potencia</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Documentos
                    de identidad, registros financieros personales e informaci&oacute;n personal</span></p>
        </li>
    </ul>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            De cualquier forma, incluidas las listas de correo</span></p>
    <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Drogas
                    ilegales y parafernalia de drogas (o cualquier menci&oacute;n de art&iacute;culos o actividades
                    relacionados)</span></p>
        </li>
    </ul>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Incluidos narc&oacute;ticos, esteroides, etc.</span></p>
    <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Art&iacute;culos
                    y servicios ilegales</span></p>
        </li>
    </ul>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Incluyendo cualquier dispositivo que sea principalmente &uacute;til para la interceptaci&oacute;n encubierta
            de comunicaciones privadas.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Incluyendo listados o productos que promueven el uso de dispositivos fotogr&aacute;ficos para ver o grabar
            de forma encubierta a personas con fines sexuales.</span></p>
    <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Productos
                    de vida silvestre ilegales o no autorizados</span></p>
        </li>
    </ul>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Incluidas trampas para osos (independientemente del tama&ntilde;o)</span></p>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Productos de bolas de musgo</span></p>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Organismos vivos ilegales o no autorizados</span></p>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Plagas reguladas</span></p>
    <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Marfil
                    o hueso</span></p>
        </li>
    </ul>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Incluidos colmillos de mamut fosilizados</span></p>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Nota: Se permiten antig&uuml;edades que contengan un 5% o menos de marfil real y hayan sido elaboradas antes
            del a&ntilde;o 1900, espec&iacute;ficamente instrumentos musicales y muebles. El a&ntilde;o en que se
            fabric&oacute; el art&iacute;culo, as&iacute; como una imagen, deben incluirse en el anuncio.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Nota: Se permite el hueso, incluidos los dientes o huesos fosilizados, de animales que no produzcan marfil.
            La especie debe estar indicada en el anuncio.</span></p>
    <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Cuchillos</span>
            </p>
        </li>
    </ul>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Nota: Se permiten cuchillos y espadas de colecci&oacute;n (sin afilar, fantas&iacute;a / antig&uuml;edades)
            y utensilios de cocina y cubiertos.</span></p>
    <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Boletos
                    de loter&iacute;a, participaciones en sorteos y m&aacute;quinas tragamonedas que funcionan con
                    monedas</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Servicios
                    de masajes</span></p>
        </li>
    </ul>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Las im&aacute;genes publicitarias deben limitarse a tomas de la cabeza o fotograf&iacute;as de la
            instalaci&oacute;n (no im&aacute;genes de cuerpo completo)</span></p>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Nota: Se permiten anuncios publicados por un terapeuta de masaje registrado (RMT) que pueda proporcionar
            recibos de seguro para los clientes.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Nota: Se permite el masaje como parte de un paquete de est&eacute;tica realizado por un esteticista
            registrado, pero no se ofrece por s&iacute; solo.</span></p>
    <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Material
                    ofensivo y obsceno</span></p>
        </li>
    </ul>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Incluyendo artefactos y coleccionables relacionados con los nazis</span></p>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Nota: Esto excluye sellos, cartas y sobres con el s&iacute;mbolo nazi, moneda emitida por el gobierno nazi,
            guiones militares y kits de modelos militares de la Segunda Guerra Mundial hist&oacute;ricamente precisos
            que tengan s&iacute;mbolos nazis.</span></p>
    <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Medicamentos
                    y dispositivos recetados</span></p>
        </li>
    </ul>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Nota: Los dispositivos de presi&oacute;n positiva continua en las v&iacute;as respiratorias (CPAP),
            presi&oacute;n positiva de dos niveles en las v&iacute;as respiratorias (BiPAP) y los dispositivos y
            accesorios de presi&oacute;n positiva variable en las v&iacute;as respiratorias (VPAP) requieren receta
            m&eacute;dica y, por lo tanto, no se permiten. Se permiten las versiones de venta libre (OTC) de estos
            dispositivos y accesorios.</span></p>
    <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Productos
                    no autorizados por Health Canada</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Equipos
                    de radio u otros dispositivos que violen la Ley de radiocomunicaciones</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Art&iacute;culos
                    retirados</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
                    style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Productos
                    alimenticios restringidos</span></p>
        </li>
    </ul>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Incluyendo pero no limitado a:</span></p>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Productos l&aacute;cteos no pasteurizados</span></p>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Jugo de frutas o verduras que no se haya pasteurizado con calor.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Hongos silvestres</span></p>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Fruto de Ackee (incluidas las semillas)</span></p>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Alimentos que est&aacute;n sujetos a retiro del mercado por parte de la Agencia Canadiense de
            Inspecci&oacute;n de Alimentos</span></p>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Alimentos y f&oacute;rmula para beb&eacute;s vencidos</span></p>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Alimentos o f&oacute;rmula para beb&eacute;s que se han abierto o manipulado</span></p>
    <p dir="ltr" style="line-height:1.38;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">-
            Comidas listas para comer (MRE) que han estado vencidas por m&aacute;s de 3 a&ntilde;os</span></p>
    <p><br></p>
    <p><br></p>
    <p><br></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Notificaci&oacute;n
            de infracciones de propiedad intelectual.</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">No
            publique contenido que infrinja los derechos de terceros. Esto incluye, entre otros, contenido que infringe
            los derechos de propiedad intelectual, como los derechos de autor y las marcas comerciales (por ejemplo,
            ofrecer art&iacute;culos falsificados a la venta). No publique contenido que contenga alguna referencia o
            semejanza de terceros identificables, a menos que se haya obtenido el consentimiento de cada individuo que
            pueda ser necesario. Nos reservamos el derecho de eliminar contenido cuando tengamos motivos para sospechar
            la violaci&oacute;n de estos t&eacute;rminos, nuestras pol&iacute;ticas o los derechos de cualquier parte.
            Los siguientes pasos son para informar a LatinosVentas de un listado que usted cree que infringe sus
            derechos de propiedad intelectual. Solo el propietario de los derechos de propiedad intelectual puede
            informar sobre art&iacute;culos o listados potencialmente infractores a trav&eacute;s del Programa VeRO de
            LatinosVentas. Si cree de buena fe que una lista en cualquiera de los Servicios infringe sus derechos de
            autor, marca registrada u otros derechos de propiedad intelectual, todo lo que necesita hacer es descargar
            nuestro formulario de Notificaci&oacute;n de infracci&oacute;n reclamada (NOCI), completarlo y enviar un
            correo electr&oacute;nico env&iacute;elo a LatinosVentas a la direcci&oacute;n de correo electr&oacute;nico
            proporcionada en el formulario. Despu&eacute;s de recibir su primer NOCI, LatinosVentas confirmar&aacute; su
            inscripci&oacute;n en nuestro programa. Nos reservamos el derecho de compartir, de acuerdo con la ley
            aplicable, el formulario NOCI completado con el tercero que originalmente public&oacute; la lista
            potencialmente infractora.</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Renuncias
            y limitaciones de responsabilidad.</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Los
            Servicios se proporcionan &quot;tal cual&quot; y &quot;seg&uacute;n disponibilidad&quot;. Acepta no hacernos
            responsables de las cosas que publiquen o hagan otros usuarios. Tambi&eacute;n acepta no responsabilizarnos
            por el procesamiento de pagos de otros proveedores de servicios como Adyen (cada uno un &quot;Procesador de
            pagos de terceros&quot;. Para mayor certeza, seg&uacute;n corresponda, si vincula una cuenta de Procesador
            de pagos de terceros a su Cuenta de LatinosVentas, usted reconoce y acepta que al realizar pagos a
            trav&eacute;s de LatinosVentas con ese Procesador de pagos de terceros, est&aacute; sujeto a los
            t&eacute;rminos y condiciones aplicables de ese Procesador de pagos de terceros, y no somos responsables de
            ninguna p&eacute;rdida, reclamo o da&ntilde;o que surja en relaci&oacute;n con ese tercero servicios del
            partido). Como la mayor&iacute;a de las cosas de los Servicios provienen de otros usuarios, no garantizamos
            la precisi&oacute;n, integridad, eficacia o puntualidad de las publicaciones o comunicaciones de los
            usuarios o la calidad, seguridad o legalidad de lo que se ofrece. Tampoco podemos garantizar el acceso
            continuo o seguro a nuestros Servicios. Es posible que la funcionalidad de notificaci&oacute;n en nuestros
            Servicios no ocurra en tiempo real. Dicha funcionalidad est&aacute; sujeta a retrasos fuera de nuestro
            control, incluidos, entre otros, retrasos o latencia debido a su ubicaci&oacute;n f&iacute;sica o la red de
            su proveedor de servicios de datos inal&aacute;mbricos. En consecuencia, en la medida en que lo permita la
            ley, renunciamos expresamente a todas las garant&iacute;as, representaciones y condiciones, expresas o
            impl&iacute;citas, incluidas las de calidad, comerciabilidad, calidad comercial, durabilidad, idoneidad para
            un prop&oacute;sito particular y aquellas que surjan por ley. No somos responsables de ninguna
            p&eacute;rdida, ya sea de dinero (incluidas las ganancias), fondo de comercio o reputaci&oacute;n, o
            cualquier da&ntilde;o especial, indirecto o consecuente que surja de su uso de LatinosVentas, incluso si nos
            avisa o podr&iacute;amos prever razonablemente la posibilidad. de cualquier da&ntilde;o que ocurra. Algunas
            jurisdicciones no permiten la exenci&oacute;n de responsabilidad de las garant&iacute;as o la
            exclusi&oacute;n de da&ntilde;os, por lo que es posible que dichas exenciones y exclusiones no se apliquen
            en su caso.</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">A
            pesar del p&aacute;rrafo anterior, si se determina que somos responsables, nuestra responsabilidad hacia
            usted o cualquier tercero (ya sea por contrato, agravio, negligencia, responsabilidad estricta en agravio,
            por ley o de otro modo) se limita al mayor de (a) el las tarifas totales que nos paga en los 12 meses
            anteriores a la acci&oacute;n que da lugar a la responsabilidad, y (b) 100 d&oacute;lares
            canadienses.</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Indemnizaci&oacute;n.</span>
    </p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Usted
            indemnizar&aacute; y mantendr&aacute; indemne a LatinosVentas y sus afiliados y a nuestros y sus respectivos
            funcionarios, directores, agentes y empleados (Parte indemnizada), de cualquier reclamo realizado por un
            tercero, junto con cualquier monto pagadero al tercero, ya sea en un acuerdo o seg&uacute;n se pueda otorgar
            de otro modo, y los costos legales razonables incurridos por cualquiera de las Partes indemnizadas, que
            surjan de o est&eacute;n relacionados con su uso de los Servicios, cualquier presunta violaci&oacute;n por
            su parte de los t&eacute;rminos aplicables, y cualquier presunta violaci&oacute;n por su parte de cualquier
            ley o reglamento aplicable. Nos reservamos el derecho, por nuestra propia cuenta, de asumir la defensa y el
            control exclusivos de cualquier asunto sujeto a indemnizaci&oacute;n por su parte, pero hacerlo no
            excusar&aacute; sus obligaciones de indemnizaci&oacute;n.</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Informaci&oacute;n
            personal.</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Al
            utilizar los Servicios, usted acepta la recopilaci&oacute;n, transferencia, almacenamiento y uso de su
            informaci&oacute;n personal por parte de LatinosVentas Canada Ltd. (el &quot;controlador de datos&quot;) en
            servidores ubicados en los Estados Unidos y en la Uni&oacute;n Europea, como se describe con m&aacute;s
            detalle en nuestra pol&iacute;tica de privacidad. .</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Rese&ntilde;as.
            Es posible que se le solicite que deje una rese&ntilde;a cuando env&iacute;e mensajes a otros usuarios en
            LatinosVentas. Las rese&ntilde;as consisten en una calificaci&oacute;n de 1 a 5 estrellas y las razones
            seleccionadas por las que eligi&oacute; esa rese&ntilde;a. La rese&ntilde;a enviada, el nombre de usuario,
            la foto de perfil (si est&aacute; disponible) y la categor&iacute;a de la lista del vendedor se har&aacute;n
            p&uacute;blicos. Las rese&ntilde;as de los usuarios deben dejarse de buena fe, asegur&aacute;ndose de que
            tanto usted como el otro usuario describan su interacci&oacute;n de manera justa. Al dejar una rese&ntilde;a
            en la cuenta de otro usuario, la rese&ntilde;a debe:</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Sea
            sincero con lo que sucedi&oacute; entre usted y el otro usuario.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">no
            dejarse en un intento de acosar o abusar de otro usuario</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">no
            quedar en un intento de manipular o enga&ntilde;ar a otros usuarios</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Para
            garantizar la integridad del sistema de rese&ntilde;as, los usuarios no pueden editar ni eliminar
            rese&ntilde;as en su propio perfil ni en el de otra persona. LatinosVentas tampoco mediar&aacute; en
            disputas relacionadas con revisiones. Las rese&ntilde;as de los usuarios reflejan las opiniones de los
            usuarios individuales de LatinosVentas y no reflejan la opini&oacute;n de LatinosVentas.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Nos
            reservamos el derecho a eliminar las rese&ntilde;as que infrinjan nuestras pol&iacute;ticas o directrices.
            Para obtener m&aacute;s informaci&oacute;n, lea nuestra pol&iacute;tica de revisi&oacute;n de
            usuarios.</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">General.</span>
    </p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Estos
            t&eacute;rminos y las dem&aacute;s pol&iacute;ticas publicadas en los Servicios constituyen el acuerdo
            completo entre nosotros y usted, reemplazando cualquier acuerdo anterior. Este acuerdo se rige por las leyes
            de la provincia de Ontario y las leyes federales de Canad&aacute; aplicables all&iacute;. Ambos nos
            sometemos a la jurisdicci&oacute;n de los tribunales de la provincia de Ontario. Esto no afectar&aacute; sus
            derechos legales si usted es un consumidor y la ley del consumidor aplicable requiere la aplicaci&oacute;n
            de otra ley para ciertos temas. Si no aplicamos ninguna disposici&oacute;n en particular, no renunciaremos a
            nuestro derecho a hacerlo m&aacute;s adelante. Si un tribunal anula cualquiera de estos t&eacute;rminos, los
            t&eacute;rminos restantes sobrevivir&aacute;n. Podemos asignar autom&aacute;ticamente este acuerdo a nuestro
            exclusivo criterio de acuerdo con la disposici&oacute;n de notificaci&oacute;n a continuaci&oacute;n
            (nuestra asignaci&oacute;n a un afiliado no requerir&aacute; notificaci&oacute;n). A excepci&oacute;n de los
            avisos relacionados con contenido ilegal o infractor, los avisos que nos env&iacute;e deben enviarse por
            correo certificado a:</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">LatinosVentas
            Canada Ltd.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">500
            King St. W.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Suite
            200, Toronto, Ontario</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">M5V
            1L9</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Re:
            consultas del usuario</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Le
            enviaremos avisos a trav&eacute;s de la direcci&oacute;n de correo electr&oacute;nico que proporcione o por
            correo certificado. Las notificaciones enviadas por correo certificado se considerar&aacute;n recibidas
            cinco d&iacute;as despu&eacute;s de la fecha de env&iacute;o. Podemos actualizar este acuerdo en cualquier
            momento, y las actualizaciones entrar&aacute;n en vigencia la pr&oacute;xima vez que use el sitio o
            despu&eacute;s de 30 d&iacute;as, lo que ocurra primero. Ninguna otra enmienda a este acuerdo ser&aacute;
            efectiva a menos que se haga por escrito, firmada por los usuarios y por nosotros. Env&iacute;e preguntas,
            comentarios o quejas al servicio de atenci&oacute;n al cliente de LatinosVentas.</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">T&eacute;rminos
            de dispositivos m&oacute;viles</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Si
            accede a los Servicios desde un dispositivo m&oacute;vil utilizando una aplicaci&oacute;n m&oacute;vil de
            LatinosVentas (la &quot;Aplicaci&oacute;n&quot;), se le aplican los siguientes t&eacute;rminos y condiciones
            (&quot;T&eacute;rminos de dispositivos m&oacute;viles&quot;), adem&aacute;s del Aviso legal y de privacidad
            m&oacute;vil correspondiente. Acuerdo de licencia de usuario, seg&uacute;n sea el caso. Su uso de la
            Aplicaci&oacute;n confirma su aceptaci&oacute;n de estos T&eacute;rminos de dispositivos
            m&oacute;viles.</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Uso
            de la aplicaci&oacute;n.</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">LatinosVentas
            le otorga el derecho de usar la Aplicaci&oacute;n de conformidad con las siguientes restricciones: no puede
            (a) modificar, copiar, publicar, licenciar, vender o comercializar de otro modo esta Aplicaci&oacute;n o
            cualquier informaci&oacute;n o software asociado con esta Aplicaci&oacute;n; (b) alquilar, arrendar o
            transferir los derechos de esta Aplicaci&oacute;n; o (c) usar esta Aplicaci&oacute;n de cualquier manera que
            pueda da&ntilde;ar la Aplicaci&oacute;n o interferir con el uso o disfrute de la Aplicaci&oacute;n por parte
            de cualquier parte. Debe cumplir con todas las leyes aplicables y los t&eacute;rminos del acuerdo de
            terceros al usar la Aplicaci&oacute;n (por ejemplo, su acuerdo de servicio de datos inal&aacute;mbricos). Es
            posible que la Aplicaci&oacute;n no contenga la misma funcionalidad disponible en el sitio web de
            LatinosVentas (y es posible que ciertas funcionalidades no est&eacute;n disponibles seg&uacute;n la
            Aplicaci&oacute;n y / o dispositivo m&oacute;vil que est&eacute; utilizando). Su descarga y uso de la
            Aplicaci&oacute;n es a su propia discreci&oacute;n y riesgo, y usted es el &uacute;nico responsable de
            cualquier da&ntilde;o a su (s) dispositivo (s) de hardware o p&eacute;rdida de datos que resulte de la
            descarga o uso de la Aplicaci&oacute;n.</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Propiedad
            intelectual - Aplicaciones.</span><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
    </p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">LatinosVentas
            posee, o es el licenciatario de, todos los derechos, t&iacute;tulos e intereses en y para sus Aplicaciones,
            incluidos todos los derechos de patente, derechos de autor, secretos comerciales, marcas comerciales y
            cualquier otro derecho de propiedad, incluidas todas las aplicaciones, renovaciones y extensiones. y
            restauraciones de los mismos. No modificar&aacute;, adaptar&aacute;, traducir&aacute;, preparar&aacute;
            trabajos derivados de, descompilar&aacute;, realizar&aacute; ingenier&iacute;a inversa, desensamblar&aacute;
            ni intentar&aacute; derivar el c&oacute;digo fuente de ninguna Aplicaci&oacute;n y no eliminar&aacute;,
            oscurecer&aacute; ni alterar&aacute; el aviso de derechos de autor de LatinosVentas, las marcas comerciales
            u otros derechos de propiedad. avisos de derechos adheridos a, contenidos en, o accedidos junto con o por
            cualquier Aplicaci&oacute;n.</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Pol&iacute;tica
            de Pa&iacute;ses Prohibidos y Regulaci&oacute;n de Comercio Exterior - Aplicaciones</span><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.&nbsp;</span>
    </p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">La
            Aplicaci&oacute;n o su tecnolog&iacute;a subyacente no se puede descargar ni exportar ni reexportar: (a) a
            (oa un residente o nacional de) ning&uacute;n pa&iacute;s sujeto al embargo de los Estados Unidos; (b) a
            cualquier persona incluida en la lista de ciudadanos especialmente designados del Departamento del Tesoro de
            EE. UU. o en la lista de entidades o partes denegadas del Departamento de Comercio de EE. UU.; y (c) a
            cualquier pa&iacute;s, persona, usuario final o entidad prohibidos especificados por las leyes de
            exportaci&oacute;n de EE. UU. Al usar la Aplicaci&oacute;n, usted es responsable de cumplir con las
            regulaciones comerciales y las leyes nacionales y extranjeras (por ejemplo, no se encuentra en un
            pa&iacute;s que est&eacute; sujeto a un embargo del gobierno de EE. UU. O que haya sido designado por el
            gobierno de EE. UU. Como &quot; &quot;pa&iacute;s de apoyo al terrorismo&quot;, y usted no figura en ninguna
            lista del gobierno de EE. UU. de partes prohibidas o restringidas).</span></p>
    <p><br></p>
    <p><br></p>
    <p><br></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Terminos
            adicionales.&nbsp;</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">T&eacute;rminos
            y condiciones adicionales que se aplican a usted seg&uacute;n el dispositivo m&oacute;vil en el que
            est&aacute; instalada la Aplicaci&oacute;n:</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
    </p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">iOS
            - Apple</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Estos
            t&eacute;rminos son un acuerdo entre usted y nosotros, y no con Apple. Apple no es responsable de la
            Aplicaci&oacute;n y su contenido.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Le
            otorgamos el derecho de usar la Aplicaci&oacute;n solo en un producto iOS de su propiedad o bajo su control
            y seg&uacute;n lo permitan las Reglas de uso establecidas en los T&eacute;rminos y condiciones de los
            servicios multimedia de la App Store vigentes en ese momento.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Apple
            no tiene obligaci&oacute;n alguna de proporcionar ning&uacute;n servicio de mantenimiento y soporte con
            respecto a la Aplicaci&oacute;n.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Apple
            no es responsable de la investigaci&oacute;n, la defensa, el acuerdo y el descargo de ning&uacute;n reclamo
            por infracci&oacute;n de propiedad intelectual de terceros.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Apple
            no es responsable de abordar ninguna reclamaci&oacute;n suya o de un tercero en relaci&oacute;n con la
            Aplicaci&oacute;n o su posesi&oacute;n y / o uso de la Aplicaci&oacute;n, incluidos, entre otros: (a)
            reclamaciones de responsabilidad por productos; (b) cualquier reclamo de que la Aplicaci&oacute;n no se
            ajusta a cualquier requisito legal o reglamentario aplicable; y (c) reclamaciones derivadas de la
            protecci&oacute;n del consumidor o legislaci&oacute;n similar.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">En
            el caso de que la Aplicaci&oacute;n no cumpla con cualquier garant&iacute;a aplicable, puede notificar a
            Apple, y Apple puede reembolsarle el precio de compra, si corresponde, por la Aplicaci&oacute;n; y en la
            medida m&aacute;xima permitida por la ley aplicable, Apple no tendr&aacute; ninguna otra obligaci&oacute;n
            de garant&iacute;a con respecto a la Aplicaci&oacute;n.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Apple
            y las subsidiarias de Apple son terceros beneficiarios de estos t&eacute;rminos y, una vez que los acepte,
            Apple, como tercero beneficiario de los mismos, tendr&aacute; el derecho (y se considerar&aacute; que ha
            aceptado el derecho) de hacer cumplir estos t&eacute;rminos en su contra.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
    </p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Android:
            Google</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Estos
            t&eacute;rminos son un acuerdo entre usted y nosotros, no con Google. Google no es responsable de la
            Aplicaci&oacute;n y su contenido.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Su
            uso de la Aplicaci&oacute;n debe cumplir con las Condiciones de servicio de Google Play Store vigentes en
            ese momento;</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Google
            es solo un proveedor de Google Play Store donde obtuvo la Aplicaci&oacute;n;</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Google
            no tiene ninguna obligaci&oacute;n ni responsabilidad con respecto a usted con respecto a la
            Aplicaci&oacute;n o estos t&eacute;rminos; y</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Usted
            reconoce y acepta que Google es un tercero beneficiario de estos t&eacute;rminos en lo que respecta a la
            Aplicaci&oacute;n.</span></p>
    <p><br></p>
    <p><br></p>
    <p><br></p>
</div>