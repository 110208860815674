import { AuthInterceptor } from './@interceptors/auth.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSpinnerModule } from "ngx-spinner";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PrincipalComponent } from './principal/principal.component';
import { DetalleproductoComponent } from './detalleproducto/detalleproducto.component';
import { SubcategoriasComponent } from './subcategorias/subcategorias.component';
import { AnunciosComponent } from './anuncios/anuncios.component';
import { LoginComponent } from './@modals/login/login.component';
import { JwtModule } from "@auth0/angular-jwt";
import { ImageCropperModule } from 'ngx-image-cropper';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { PinchZoomModule } from 'ngx-pinch-zoom';

import { AngularFireModule } from '@angular/fire';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegisterComponent } from './@modals/register/register.component';
import { PerfilComponent } from './cuenta/perfil/perfil.component';
import { MisanunciosComponent } from './cuenta/misanuncios/misanuncios.component';
import { MensajesComponent } from './cuenta/mensajes/mensajes.component';
import { FavoritosComponent } from './cuenta/favoritos/favoritos.component';
import { SeleccionarcategoriaComponent } from './publicacion/seleccionarcategoria/seleccionarcategoria.component';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AdsenseModule } from 'ng2-adsense';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { NgxImageCompressService } from 'ngx-image-compress';
import { SoporteComponent } from './soporte/soporte.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { FilterPipe } from './@pipes/filter.pipe';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BuscaranunciosComponent } from './buscaranuncios/buscaranuncios.component';
import { RecuperarComponent } from './@modals/recuperar/recuperar.component';
import { RestablecerComponent } from './@modals/restablecer/restablecer.component';
import { RestablecerPasswordComponent } from './cuenta/restablecer-password/restablecer-password.component';
import { EditarperfilComponent } from './cuenta/editarperfil/editarperfil.component';
import { EditaranuncioComponent } from './cuenta/editaranuncio/editaranuncio.component';
import { TerminosComponent } from './cuenta/terminos/terminos.component';
import { PrivacidadComponent } from './cuenta/privacidad/privacidad.component';
import { SortablejsModule } from 'ngx-sortablejs';
import { AnunciosusuarioComponent } from './anunciosusuario/anunciosusuario.component';
import { EulaComponent } from './cuenta/eula/eula.component';
import { NegociosComponent } from './directorio/negocios/negocios.component';
import { NuevonegocioComponent } from './directorio/nuevonegocio/nuevonegocio.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MisnegociosComponent } from './cuenta/misnegocios/misnegocios.component';
import { EditarnegocioComponent } from './directorio/editarnegocio/editarnegocio.component';
import { UiModule } from './modules/ui/ui.module';


//latinosventas-DOS
var firebaseConfig = {
  apiKey: "AIzaSyAAZb4IGWj8cQtNWK-AxAdmbv9OgS6OOeA",
  authDomain: "latinosventasca.firebaseapp.com",
  projectId: "latinosventasca",
  storageBucket: "latinosventasca.appspot.com",
  messagingSenderId: "916552060628",
  appId: "1:916552060628:web:01a116bd53756fea04b541",
  measurementId: "G-4G70H85CV5"
};


export function tokenGetter() {
  return localStorage.getItem("token");
}

@NgModule({
  declarations: [
    AppComponent,
    PrincipalComponent,
    DetalleproductoComponent,
    SubcategoriasComponent,
    AnunciosComponent,
    LoginComponent,
    RegisterComponent,
    PerfilComponent,
    MisanunciosComponent,
    MensajesComponent,
    FavoritosComponent,
    SeleccionarcategoriaComponent,
    SoporteComponent,
    FilterPipe,
    BuscaranunciosComponent,
    RecuperarComponent,
    RestablecerComponent,
    RestablecerPasswordComponent,
    EditarperfilComponent,
    EditaranuncioComponent,
    TerminosComponent,
    PrivacidadComponent,
    AnunciosusuarioComponent,
    EulaComponent,
    NegociosComponent,
    NuevonegocioComponent,
    MisnegociosComponent,
    EditarnegocioComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    CarouselModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxSpinnerModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAnalyticsModule,
    FontAwesomeModule,
    FormsModule, ReactiveFormsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: [""],
        disallowedRoutes: [""],
      },
    }),
    ImageCropperModule,
    GooglePlaceModule,
    AdsenseModule.forRoot({
      adClient: 'ca-pub-7744668111711401',
      adSlot: 7259870550,
    }),
    AngularFireStorageModule,
    FilterPipeModule,
    PinchZoomModule,
    ToastrModule.forRoot({ positionClass: 'toast-bottom-right'}),
    NgbModule,
    SortablejsModule.forRoot({ animation: 150 }),
    NgSelectModule,
    UiModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [NgxImageCompressService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },AppComponent
    // { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
