import { first } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from './../_services/api.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subcategorias',
  templateUrl: './subcategorias.component.html',
  styleUrls: ['./subcategorias.component.css']
})
export class SubcategoriasComponent implements OnInit {

  subcategorias: any = [];

  constructor(private api: ApiService, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe(params => {
      console.log('activatedRoute', params['subcat']);
      this.cargarSubcategorias(params['subcat']);
    });
  }

  ngOnInit(): void {
  }

  cargarSubcategorias(subcategoria): void {
    this.api.getResponse('subcategorias/' + subcategoria).pipe(first()).subscribe(data => {
      console.log(data);
      this.subcategorias = data;
    }, err => { console.log(err) });
  }

  abrirProductos(id): string {
    return '/Anuncios/' + id;
  }

}
