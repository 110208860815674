<div class="modal-header">
    <h4 class="modal-title">Restablece tu contraseña</h4>
  </div>
  <div class="modal-body">
    <form [formGroup]="registerForm">

      <div style="margin-top: 5px;">
        <input type="password" class="form-control" formControlName="password" placeholder="Ingresa una nueva contraseña" required>
        <div class="alert alert-danger"
          *ngIf="registerForm.get('password').invalid  && (registerForm.get('password').dirty )">
          <div *ngIf="registerForm.get('password').errors.required">
            Ingresa una contraseña
          </div>
        </div>
      </div>
  
      <div style="margin-top: 5px;">
        <input type="password" class="form-control" formControlName="confirmPassword" placeholder="Repite tu nueva contraseña"
          required>
        <div class="alert alert-danger"
          *ngIf="registerForm.get('confirmPassword').invalid  && (registerForm.get('confirmPassword').dirty )">
          <div *ngIf="registerForm.get('confirmPassword').errors.required">
            Ingresa una contraseña
          </div>
        </div>
      </div>
  
      <div class="text-center">
        <!-- <div class="btn-continue-pr center-div" style="margin-top: 15px;" (click)="continue()">Continuar</div> -->
        <!-- [disabled]="registerForm.invalid" -->
        <div class="alert alert-danger" *ngIf="this.message && !this.messageSuccess" style="margin-top: 10px;">
          {{this.message}}
        </div>
  
        <div class="alert alert-success" *ngIf="this.messageSuccess && !this.message" style="margin-top: 10px;">
          {{this.messageSuccess}}
        </div>
  
        <button type="button" [disabled]="registerForm.invalid" (click)="this.registerWithEmail()"
          class="btn btn-lt-danger">
          <div *ngIf="isLoading" class="spinner-border btn-spinner" role="status">
          </div>
          <span>Restablecer</span>
        </button>
  
      </div>
    </form>
  </div>
  
