<button class="btn btn-secondary" (click)="fileChangeEvent($event)">Elegir imagen</button>
<!-- <img *ngIf="imgResultBeforeCompress" [src]="imgResultBeforeCompress" alt=""> -->
<img *ngIf="imgResultAfterCompress" [src]="imgResultAfterCompress" alt=""
  style="    width: 150px;height: 150px;padding: 10px;border-radius: 91px;object-fit: cover;">
<form [formGroup]="registerForm">
    <div style="margin-top: 5px;">
      <input type="text" class="form-control" formControlName="nombre" placeholder="Nombre" required>
      <div class="alert alert-danger"
        *ngIf="registerForm.get('nombre').invalid  && (registerForm.get('nombre').dirty )">
        <div *ngIf="registerForm.get('nombre').errors.required">
          Ingresa tu nombre
        </div>
      </div>
    </div>
    <div style="margin-top: 5px;">
      <input type="text" class="form-control" formControlName="apellido" placeholder="Apellido" required>
      <div class="alert alert-danger"
        *ngIf="registerForm.get('apellido').invalid  && (registerForm.get('apellido').dirty )">
        <div *ngIf="registerForm.get('apellido').errors.required">
          Ingresa tu apellido
        </div>
      </div>
    </div>
    <div style="margin-top: 5px;">
      <input type="text" class="form-control" formControlName="nomusuario" placeholder="Nombre de usuario" required>
      <div class="alert alert-danger"
        *ngIf="registerForm.get('nomusuario').invalid  && (registerForm.get('nomusuario').dirty )">
        <div *ngIf="registerForm.get('nomusuario').errors.required">
          Ingresa un nombre de usuario
        </div>
      </div>
    </div>
    <div style="margin-top: 5px;">
      <input type="text" class="form-control" formControlName="telefono" placeholder="Teléfono (opcional)" required>
    </div>
    <!-- <div  style="margin-top: 5px;">
      <input type="text" class="form-control" formControlName="correo" placeholder="Ingresa tu correo" required>
      <div class="alert alert-danger" *ngIf="registerForm.get('correo').invalid  && registerForm.get('correo').dirty">
        <div *ngIf="registerForm.get('correo').errors.required">
          Ingresa un correo electrónico
        </div>
        <div *ngIf="registerForm.get('correo').errors.email">
          Ingresa un correo electrónico válido
        </div>
      </div>
    </div> -->


    <div class="text-center">
      <!-- <div class="btn-continue-pr center-div" style="margin-top: 15px;" (click)="continue()">Continuar</div> -->
      <!-- [disabled]="registerForm.invalid" -->
      <div class="alert alert-danger" *ngIf="this.message && !this.messageSuccess" style="margin-top: 10px;">
        {{this.message}}
      </div>

      <div class="alert alert-success" *ngIf="this.messageSuccess && !this.message" style="margin-top: 10px;">
        {{this.messageSuccess}}
      </div>

      <button type="button" [disabled]="registerForm.invalid" (click)="editar()"
        class="btn btn-lt-danger">
        <div *ngIf="isLoading" class="spinner-border btn-spinner" role="status">
        </div>
        <span>Editar perfil</span>
      </button>

    </div>
  </form>
