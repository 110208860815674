<section class="product-shop spad page-details">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="product-thumbs">

                        </div>
                        <div class="product-pic-zoom">
                            <pinch-zoom>
                                <img class="product-big-img img-current" [src]="this.imagenActual" alt="">
                            </pinch-zoom>
                        </div>
                        <div class="row" style="margin-bottom: 30px;margin-top: 30px;">
                            <div class="col-lg-2 col-md-2 col-sm-3 col-xs-4 col-2"
                                *ngFor="let item of anuncio.imagenes">
                                <img class="img-owl" [src]="item" alt="" (click)="VerImagen(item)"
                                    style="cursor:pointer;">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="product-details">
                            <div class="pd-title">
                                <h3>{{anuncio.titulo}}</h3>
                                <a href="#" class="heart-icon"><i class="icon_heart_alt"></i></a>
                            </div>

                            <div class="pd-desc">
                                <p>{{anuncio.descripcion}}</p>
                                <h4 *ngIf="!anuncio.esGratuito">{{anuncio.precio | currency : 'USD' : 'symbol' :
                                    '1.0-0'}}
                                </h4>
                                <h4 *ngIf="anuncio.esGratuito">Gratis
                                </h4>
                            </div>

                            <ul class="pd-tags">
                                <li><span>Ubicación</span>: {{anuncio.ubicacion}}</li>
                                
                                <li>
                                    <span>Vendedor</span>:
                                    <div style="display: initial;" *ngIf="anuncio?.usuarioId?.nomusuario">{{anuncio.usuarioId.nomusuario}}</div>
                                    <div style="display: initial;" *ngIf="!anuncio?.usuarioId?.nomusuario"> {{ this.ObtenerUsername(anuncio.usuarioId.correo )}}</div>
                                
                                </li>
                                <li *ngIf="anuncio?.vistas > 0"><span>Vistas del artículo:</span> {{anuncio?.vistas}}
                                    <div>
                                        <span routerLink="/AnunciosUsuario/{{anuncio?.usuarioId?._id}}"
                                            style="cursor:pointer;color:#57b1b9 !important;font-size: 12px;">
                                            Ver más de éste vendedor</span>
                                    </div></li>
                                <li *ngIf="anuncio.mostrarTelefono && anuncio.usuarioId.telefono"><span>Teléfono</span>:
                                    {{anuncio.usuarioId.telefono}}</li>
                            </ul>

                            <div class="pd-desc" *ngIf="anuncio?.usuarioId?._id != this.idUsuarioActual">
                                <form [formGroup]="mensajeForm" (ngSubmit)="enviar(anuncio)">
                                    <div class="row">
                                        <div class="col-lg-10 col-md-10 col-sm-10">
                                            <input type="text" formControlName="mensaje" class="form-control"
                                                placeholder="Escibre un mensaje al vendedor" />
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-2">
                                            <button class="btn btn-primary" type="submit"><i class="fa fa-paper-plane-o"
                                                    aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="product-tab">
                    <div class="tab-item">
                        <ul class="nav" role="tablist">
                            <li>
                                <a class="active" data-toggle="tab" href="#tab-1" role="tab">DESCRIPCION</a>
                            </li>
                            <li>
                                <a data-toggle="tab" href="#tab-2" role="tab">ESPECIFICACIONES</a>
                            </li>

                        </ul>
                    </div>
                    <div class="tab-item-content">
                        <div class="tab-content">
                            <div class="tab-pane fade-in active" id="tab-1" role="tabpanel">
                                <div class="product-content">
                                    <div class="row">
                                        <div class="col-lg-7">
                                            <p>{{anuncio.descripcion}}</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="tab-2" role="tabpanel">
                                <div class="specification-table">
                                    <table>

                                        <tr>
                                            <td class="p-catagory">Precio</td>
                                            <td>
                                                <div class="p-price">{{anuncio.precio | currency}}</div>
                                            </td>
                                        </tr>

                                        <tr *ngIf="anuncio.numHabitaciones">
                                            <td class="p-catagory">Número de habitaciones</td>
                                            <td>
                                                <div class="p-price">{{anuncio.numHabitaciones }}</div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="anuncio.numBanios">
                                            <td class="p-catagory">Número de baños</td>
                                            <td>
                                                <div class="p-price">{{anuncio.numBanios}}</div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="anuncio.estacionamientos">
                                            <td class="p-catagory">Número de estacionamientos</td>
                                            <td>
                                                <div class="p-price">{{anuncio.estacionamientos}}</div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="anuncio.superficie">
                                            <td class="p-catagory">Superficie (Km2)</td>
                                            <td>
                                                <div class="p-price">{{anuncio.superficie}}</div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="anuncio.aceptoCredito">
                                            <td class="p-catagory">Acepto créditos</td>
                                            <td>
                                                <div class="p-price">{{anuncio.aceptoCredito}}</div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="anuncio.marca">
                                            <td class="p-catagory">Marca</td>
                                            <td>
                                                <div class="p-price">{{anuncio.marca}}</div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="anuncio.tipoAuto">
                                            <td class="p-catagory">Modelo</td>
                                            <td>
                                                <div class="p-price">{{anuncio.tipoAuto}}</div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="anuncio.marcaAuto">
                                            <td class="p-catagory">Modelo</td>
                                            <td>
                                                <div class="p-price">{{anuncio.marcaAuto}}</div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="anuncio.modeloAuto">
                                            <td class="p-catagory">Modelo</td>
                                            <td>
                                                <div class="p-price">{{anuncio.modeloAuto}}</div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="anuncio.anioAuto">
                                            <td class="p-catagory">Año</td>
                                            <td>
                                                <div class="p-price">{{anuncio.anioAuto}}</div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="anuncio.transmision">
                                            <td class="p-catagory">Tipo de transmisión</td>
                                            <td>
                                                <div class="p-price">{{anuncio.transmision}}</div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="anuncio.combustible">
                                            <td class="p-catagory">Tipo de combustible</td>
                                            <td>
                                                <div class="p-price">{{anuncio.combustible}}</div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="anuncio.kilometraje">
                                            <td class="p-catagory">Kilometraje</td>
                                            <td>
                                                <div class="p-price">{{anuncio.kilometraje}}</div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="tab-3" role="tabpanel">
                                <div class="customer-review-option">
                                    <h4>2 Comments</h4>
                                    <div class="comment-option">
                                        <div class="co-item">
                                            <div class="avatar-pic">
                                                <img src="img/product-single/avatar-1.png" alt="">
                                            </div>
                                            <div class="avatar-text">
                                                <div class="at-rating">
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star-o"></i>
                                                </div>
                                                <h5>Brandon Kelley <span>27 Aug 2019</span></h5>
                                                <div class="at-reply">Nice !</div>
                                            </div>
                                        </div>
                                        <div class="co-item">
                                            <div class="avatar-pic">
                                                <img src="img/product-single/avatar-2.png" alt="">
                                            </div>
                                            <div class="avatar-text">
                                                <div class="at-rating">
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star-o"></i>
                                                </div>
                                                <h5>Roy Banks <span>27 Aug 2019</span></h5>
                                                <div class="at-reply">Nice !</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="personal-rating">
                                        <h6>Your Ratind</h6>
                                        <div class="rating">
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star-o"></i>
                                        </div>
                                    </div>
                                    <div class="leave-comment">
                                        <h4>Leave A Comment</h4>
                                        <form action="#" class="comment-form">
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <input type="text" placeholder="Name">
                                                </div>
                                                <div class="col-lg-6">
                                                    <input type="text" placeholder="Email">
                                                </div>
                                                <div class="col-lg-12">
                                                    <textarea placeholder="Messages"></textarea>
                                                    <button type="submit" class="site-btn">Send message</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="women-banner spad container" *ngIf="this.anunciosRealacionados.length > 0">
    <div>
        <h3 class="section-title">También te puede interesar</h3>
    </div>
    <div class="">

        <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide *ngFor="let item of this.anunciosRealacionados">
                <div class="product-slider shadow-box" style="  height: 350px;">

                    <div class="product-item">
                        <div class="pi-pic" style="background-color: #f9f9f9;cursor: pointer;">
                            <img [src]="item.imagenes[0]" alt="" routerLink="/detalle/{{item._id}}" class="img-anuncio">
                            <div class="icon"
                                style="background-color: white;padding: 10px;border: none;margin-top: -16px;">
                                <i class="icon_heart" *ngIf="item.tieneFavorito" style="color: red;"
                                    (click)="agregarFavoritos(item)"></i>
                                <i class="icon_heart" *ngIf="!item.tieneFavorito" (click)="agregarFavoritos(item)"></i>
                            </div>
                            <ul>

                            </ul>
                        </div>
                        <a routerLink="/detalle/{{item._id}}">
                            <div class="pi-text">
                                <h5>{{item.titulo | slice:0:30}} <span *ngIf="item.titulo.length >=30">...</span></h5>
                                <div *ngIf="!item.esGratuito" class="product-price">
                                    {{item.precio | currency : 'USD' : 'symbol' : '1.0-0'}}
                                </div>
                                <div *ngIf="item.esGratuito" class="product-price">
                                    Gratis
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </ng-template>

        </owl-carousel-o>
    </div>
</section>