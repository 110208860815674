<div class="container" style="padding: 60px;background-color: #fff;text-align: justify;font-size: 14px;">
  <h3>
    Pol&iacute;tica de Privacidad</h3>
    <br>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:18px;font-family:"Calibri",sans-serif;text-align:justify;'>
    LATINOSVENTAS te informa sobre su Pol&iacute;tica de Privacidad respecto del tratamiento y protecci&oacute;n de los
    datos de car&aacute;cter personal de los usuarios y clientes que puedan ser recabados por la navegaci&oacute;n o
    contrataci&oacute;n de servicios a trav&eacute;s del sitio Web y Apps de LATINOSVENTAS.COM.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    &nbsp;</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    En este sentido, el LATINOSVENTAS garantiza el cumplimiento de la normativa vigente en materia de protecci&oacute;n
    de
    datos personales.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    El uso de sitio Web implica la aceptaci&oacute;n de esta Pol&iacute;tica de Privacidad as&iacute; como las
    condiciones
    incluidas en el Aviso Legal.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    &nbsp;</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Identidad del responsable</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    LATINOSVENTAS: LATINOSVENTAS</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Domicilio: 8810 ROYAL BIRCH BLVD NW CALGARY ALBERTA CANADA T3G6A9</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Correo electr&oacute;nico: soporte@latinosventas.com</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Sitio Web: www.latinosventas.com</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Principios aplicados en el tratamiento de datos</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    En el tratamiento de tus datos personales, el LATINOSVENTAS aplicar&aacute; los siguientes principios que se ajustan
    a
    las exigencias del nuevo reglamento europeo de protecci&oacute;n de datos:</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    &nbsp;</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Principio de licitud, lealtad y transparencia: El LATINOSVENTAS siempre requerir&aacute; el consentimiento para el
    tratamiento de tus datos personales que puede ser para uno o varios fines espec&iacute;ficos sobre los que te
    informar&aacute; previamente con absoluta transparencia.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Principio de minimizaci&oacute;n de datos: El LATINOSVENTAS te solicitar&aacute; solo los datos estrictamente
    necesarios para el fin o los fines que los solicita.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Principio de limitaci&oacute;n del plazo de conservaci&oacute;n: Los datos se mantendr&aacute;n durante el tiempo
    estrictamente necesario para el fin o los fines del tratamiento.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    El LATINOSVENTAS te informar&aacute; del plazo de conservaci&oacute;n correspondiente seg&uacute;n la finalidad. En
    el
    caso de suscripciones, el LATINOSVENTAS revisar&aacute; peri&oacute;dicamente las listas y eliminar&aacute; aquellos
    registros inactivos durante un tiempo considerable.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Principio de integridad y confidencialidad: Tus datos ser&aacute;n tratados de tal manera que su seguridad,
    confidencialidad e integridad est&eacute; garantizada. Debes saber que el LATINOSVENTAS toma las precauciones
    necesarias para evitar el acceso no autorizado o uso indebido de los datos de sus usuarios por parte de terceros.
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Obtenci&oacute;n de datos personales</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Para navegar por SITIO-WEB no es necesario que facilites ning&uacute;n dato personal. Los casos en los que s&iacute;
    proporcionas tus datos personales son los siguientes:</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Al contactar a trav&eacute;s de los formularios de contacto o enviar un correo electr&oacute;nico.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Al realizar un comentario en un art&iacute;culo o p&aacute;gina.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Al inscribirte en un formulario de suscripci&oacute;n o un bolet&iacute;n que el LATINOSVENTAS.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Tus derechos</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    El LATINOSVENTAS te informa que sobre tus datos personales tienes derecho a:</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Solicitar el acceso a los datos almacenados.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Solicitar una rectificaci&oacute;n o la cancelaci&oacute;n.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Solicitar la limitaci&oacute;n de su tratamiento.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Oponerte al tratamiento.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Solicitar la portabilidad de tus datos.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    El ejercicio de estos derechos es personal y por tanto debe ser ejercido directamente por el interesado,
    solicit&aacute;ndolo directamente al LATINOSVENTAS, lo que significa que cualquier cliente, suscriptor o colaborador
    que haya facilitado sus datos en alg&uacute;n momento puede dirigirse al LATINOSVENTAS y pedir informaci&oacute;n
    sobre los datos que tiene almacenados y c&oacute;mo los ha obtenido, solicitar la rectificaci&oacute;n de los
    mismos,
    solicitar la portabilidad de sus datos personales, oponerse al tratamiento, limitar su uso o solicitar la
    cancelaci&oacute;n de esos datos en los ficheros del LATINOSVENTAS.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    &nbsp;</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Para ejercitar tus derechos de acceso, rectificaci&oacute;n, cancelaci&oacute;n, portabilidad y oposici&oacute;n
    tienes que enviar un correo electr&oacute;nico a soporte@latinosventas.com junto con la prueba v&aacute;lida en
    derecho como una fotocopia de una identificaci&oacute;n.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    &nbsp;</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Tienes derecho a la tutela judicial efectiva y a presentar una reclamaci&oacute;n ante la autoridad de control, en
    este caso, la Agencia Espa&ntilde;ola de Protecci&oacute;n de Datos, si consideras que el tratamiento de datos
    personales que te conciernen infringe el Reglamento.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    &nbsp;</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Finalidad del tratamiento de datos personales</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Cuando te conectas al sitio Web para mandar un correo al LATINOSVENTAS, te suscribes a su bolet&iacute;n o realizas
    una contrataci&oacute;n, est&aacute;s facilitando informaci&oacute;n de car&aacute;cter personal de la que el
    responsable es el LATINOSVENTAS. Esta informaci&oacute;n puede incluir datos de car&aacute;cter personal como pueden
    ser tu direcci&oacute;n IP, nombre y apellidos, direcci&oacute;n f&iacute;sica, direcci&oacute;n de correo
    electr&oacute;nico, n&uacute;mero de tel&eacute;fono, y otra informaci&oacute;n. Al facilitar esta
    informaci&oacute;n,
    das tu consentimiento para que tu informaci&oacute;n sea recopilada, utilizada, gestionada y almacenada por
    LATINOSVENTAS, s&oacute;lo como se describe en el Aviso Legal y en la presente Pol&iacute;tica de Privacidad.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    &nbsp;</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Los datos personales y la finalidad del tratamiento por parte del LATINOSVENTAS son diferente seg&uacute;n el
    sistema
    de captura de informaci&oacute;n:</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Formularios de contacto: El LATINOSVENTAS solicita datos personales entre los que pueden estar: Nombre y apellidos,
    direcci&oacute;n de correo electr&oacute;nico, n&uacute;mero de tel&eacute;fono y direcci&oacute;n de tu sitio Web
    con
    la finalidad de responder a tus consultas.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Por ejemplo, el LATINOSVENTAS utiliza esos datos para dar respuesta a tus mensajes, dudas, quejas, comentarios o
    inquietudes que puedas tener relativas a la informaci&oacute;n incluida en el sitio Web, los servicios que se
    prestan
    a trav&eacute;s del sitio Web, el tratamiento de tus datos personales, cuestiones referentes a los textos legales
    incluidos en el sitio Web, as&iacute; como cualquier otra consulta que puedas tener y que no est&eacute; sujeta a
    las
    condiciones del sitio Web o de la contrataci&oacute;n.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Formularios de suscripci&oacute;n a contenidos: El LATINOSVENTAS solicita los siguientes datos personales: Nombre y
    apellidos, direcci&oacute;n de correo electr&oacute;nico, n&uacute;mero de tel&eacute;fono y direcci&oacute;n de tu
    sitio web para gestionar la lista de suscripciones, enviar boletines, promociones y ofertas especiales.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Existen otras finalidades por las que el LATINOSVENTAS trata tus datos personales:</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Para garantizar el cumplimiento de las condiciones recogidas en el Aviso Legal y en la ley aplicable. Esto puede
    incluir el desarrollo de herramientas y algoritmos que ayuden a este sitio Web a garantizar la confidencialidad de
    los
    datos personales que recoge.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Para apoyar y mejorar los servicios que ofrece este sitio Web.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Para analizar la navegaci&oacute;n. El LATINOSVENTAS recoge otros datos no identificativos que se obtienen mediante
    el
    uso de cookies que se descargan en tu ordenador cuando navegas por el sitio Web cuyas caracter&iacute;sticas y
    finalidad est&aacute;n detalladas en la Pol&iacute;tica de Cookies.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Para gestionar las redes sociales. El LATINOSVENTAS tiene presencia en redes sociales. Si te haces seguidor en las
    redes sociales del LATINOSVENTAS el tratamiento de los datos personales se regir&aacute; por este apartado,
    as&iacute;
    como por aquellas condiciones de uso, pol&iacute;ticas de privacidad y normativas de acceso que pertenezcan a la red
    social que proceda en cada caso y que has aceptado previamente.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    El LATINOSVENTAS tratar&aacute; tus datos personales con la finalidad de administrar correctamente su presencia en
    la
    red social, informarte de sus actividades, productos o servicios, as&iacute; como para cualquier otra finalidad que
    las normativas de las redes sociales permitan.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    &nbsp;</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    En ning&uacute;n caso el LATINOSVENTAS utilizar&aacute; los perfiles de seguidores en redes sociales para enviar
    publicidad de manera individual.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    &nbsp;</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Seguridad de los datos personales</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Para proteger tus datos personales, el LATINOSVENTAS toma todas las precauciones razonables y sigue las mejores
    pr&aacute;cticas de la industria para evitar su p&eacute;rdida, mal uso, acceso indebido, divulgaci&oacute;n,
    alteraci&oacute;n o destrucci&oacute;n de los mismos.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    &nbsp;</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    El sitio Web est&aacute; alojado en PROVEEDOR DE ALOJAMIENTO WEB. La seguridad de tus datos est&aacute; garantizada,
    ya que toman todas las medidas de seguridad necesarias para ello. Puedes consultar su pol&iacute;tica de privacidad
    para tener m&aacute;s informaci&oacute;n.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    &nbsp;</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Contenido de otros sitios web</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Las p&aacute;ginas de este sitio Web pueden incluir contenido incrustado (por ejemplo, v&iacute;deos,
    im&aacute;genes,
    art&iacute;culos, etc.). El contenido incrustado de otras web se comporta exactamente de la misma manera que si
    hubieras visitado la otra web.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    &nbsp;</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Estos sitios Web pueden recopilar datos sobre ti, utilizar cookies, incrustar un c&oacute;digo de seguimiento
    adicional de terceros, y supervisar tu interacci&oacute;n usando este c&oacute;digo.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    &nbsp;</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Pol&iacute;tica de Cookies</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Para que este sitio Web funcione correctamente necesita utilizar cookies, que es una informaci&oacute;n que se
    almacena en tu navegador web.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    &nbsp;</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    En la p&aacute;gina Pol&iacute;tica de Cookies puedes consultar toda la informaci&oacute;n relativa a la
    pol&iacute;tica de recogida, la finalidad y el tratamiento de las cookies.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    &nbsp;</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Legitimaci&oacute;n para el tratamiento de datos</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    La base legal para el tratamiento de tus datos es: el consentimiento.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    &nbsp;</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Para contactar con el LATINOSVENTAS, suscribirte a un bolet&iacute;n o realizar comentarios en este sitio Web tienes
    que aceptar la presente Pol&iacute;tica de Privacidad.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    &nbsp;</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Categor&iacute;as de datos personales</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Las categor&iacute;as de datos personales que trata el LATINOSVENTAS son:</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    &nbsp;</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Datos identificativos.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Conservaci&oacute;n de datos personales</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Los datos personales que proporciones al LATINOSVENTAS se conservar&aacute;n hasta que solicites su
    supresi&oacute;n.
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    &nbsp;</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Destinatarios de datos personales</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Google Analytics es un servicio de anal&iacute;tica web prestado por Google, Inc., una compa&ntilde;&iacute;a de
    Delaware cuya oficina principal est&aacute; en 1600 Amphitheatre Parkway, Mountain View (California), CA 94043,
    Estados Unidos (&ldquo;Google&rdquo;). Encontrar&aacute;s m&aacute;s informaci&oacute;n en:
    https://analytics.google.com</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Google Analytics utiliza &ldquo;cookies&rdquo;, que son archivos de texto ubicados en tu ordenador, para ayudar al
    LATINOSVENTAS a analizar el uso que hacen los usuarios del sitio Web. La informaci&oacute;n que genera la cookie
    acerca del uso del sitio Web (incluyendo tu direcci&oacute;n IP) ser&aacute; directamente transmitida y archivada
    por
    Google en los servidores de Estados Unidos.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Tambi&eacute;n puedes ver una lista de los tipos de cookies que utiliza Google y sus colaboradores y toda la
    informaci&oacute;n relativa al uso que hacen de cookies publicitarias.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    &nbsp;</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Navegaci&oacute;n Web</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Al navegar por SITIO WEB se pueden recoger datos no identificativos, que pueden incluir, la direcci&oacute;n IP,
    geolocalizaci&oacute;n, un registro de c&oacute;mo se utilizan los servicios y sitios, h&aacute;bitos de
    navegaci&oacute;n y otros datos que no pueden ser utilizados para identificarte.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    &nbsp;</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    El sitio Web utiliza los siguientes servicios de an&aacute;lisis de terceros:</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Google Analytics</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    El LATINOSVENTAS utiliza la informaci&oacute;n obtenida para obtener datos estad&iacute;sticos, analizar tendencias,
    administrar el sitio, estudiar patrones de navegaci&oacute;n y para recopilar informaci&oacute;n demogr&aacute;fica.
  </p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Exactitud y veracidad de los datos personales</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Te comprometes a que los datos facilitados al LATINOSVENTAS sean correctos, completos, exactos y vigentes,
    as&iacute;
    como a mantenerlos debidamente actualizados.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    &nbsp;</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Como Usuario del sitio Web eres el &uacute;nico responsable de la veracidad y correcci&oacute;n de los datos que
    remitas al sitio exonerando al LATINOSVENTAS de cualquier responsabilidad al respecto.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    &nbsp;</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Aceptaci&oacute;n y consentimiento</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Como Usuario del sitio Web declaras haber sido informado de las condiciones sobre protecci&oacute;n de datos de
    car&aacute;cter personal, aceptas y consientes el tratamiento de los mismos por parte del LATINOSVENTAS en la forma
    y
    para las finalidades indicadas en esta Pol&iacute;tica de Privacidad.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    &nbsp;</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Revocabilidad</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Para ejercitar tus derechos de acceso, rectificaci&oacute;n, cancelaci&oacute;n, portabilidad y oposici&oacute;n
    tienes que enviar un correo electr&oacute;nico a CORREO ELECTR&Oacute;NICO junto con la prueba v&aacute;lida en
    derecho como una fotocopia del D.N.I. o equivalente.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    &nbsp;</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    El ejercicio de tus derechos no incluye ning&uacute;n dato que el LATINOSVENTAS est&eacute; obligado a conservar con
    fines administrativos, legales o de seguridad.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    &nbsp;</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Cambios en la Pol&iacute;tica de Privacidad</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    El LATINOSVENTAS se reserva el derecho a modificar la presente Pol&iacute;tica de Privacidad para adaptarla a
    novedades legislativas o jurisprudenciales, as&iacute; como a pr&aacute;cticas de la industria.</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    &nbsp;</p>
  <p
    style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
    Estas pol&iacute;ticas estar&aacute;n vigentes hasta que sean modificadas por otras debidamente publicadas.</p>
</div>