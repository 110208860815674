<div *ngIf="!this.isLoading">
  <div *ngIf="anuncios.length <= 0" class="center-div text-center">
    <img src="../../assets/img/notfound.png" alt="" width="100">
    <div class="h3">Aún no haz publicado anuncios</div>
  </div>
  <!-- <div class="row gallery" *ngIf="anuncios.length > 0">
    <div class="col-md-4 col-sm-6 col-12 col-mr" *ngFor="let item of anuncios">
      <div class="product-slider shadow-box" style="  height: 350px;">
        <div class="product-item">
          <div class="pi-pic" style="background-color: #f9f9f9;">
            <img [src]="item.imagenes[0]" alt="">
            <div class="icon">
              <i class="icon_trash_alt" (click)="eliminar(item._id)"></i>
              <i class="icon_pencil-edit" (click)="editar(item._id)"></i>
            </div>
            <ul>
            </ul>
          </div>
          <div class="pi-text">
            <a routerLink="/detalle/{{item._id}}">
              <h5>{{item.titulo | slice:0:35}} <span *ngIf="item.titulo.length >=35">...</span></h5>
            </a>
            <div *ngIf="!item.esGratuito" class="product-price">
              {{item.precio | currency : 'USD' : 'symbol' : '1.0-0'}}
            </div>
            <div *ngIf="item.esGratuito" class="product-price">
              Gratis
            </div>
          </div>
        </div>
      </div>
    </div>

  </div> -->
</div>

<ul class="list-group" *ngIf="anuncios.length > 0">
  <li class="list-group-item" *ngFor="let item of anuncios">
    <div class="row">
      <div class="col-lg-1 col-md-1 col-sm-2">
        <a routerLink="/detalle/{{item._id}}">
          <img [src]="item.imagenes[0]" alt="">
        </a>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="pi-text">
          <a routerLink="/detalle/{{item._id}}">
            <h5>{{item.titulo | slice:0:35}} <span *ngIf="item.titulo.length >=35">...</span></h5>
          </a>
          <div *ngIf="!item.esGratuito" class="product-price">
            {{item.precio | currency : 'USD' : 'symbol' : '1.0-0'}}
          </div>
          <div *ngIf="item.esGratuito" class="product-price">
            Gratis
          </div>
        </div>
      </div>
      <div class="col-lg-1 col-md-4 col-sm-2">
        <div class="icon">
        <i class="icon_trash_alt" style="cursor: pointer;" (click)="eliminar(item._id)"></i>
        <i class="icon_pencil-edit" style="cursor: pointer;" (click)="editar(item._id)"></i>
      </div>
      </div>
    </div>
    <!-- <div style="background-color: #f9f9f9;"> -->
      
      
      
    <!-- </div> -->
    
  </li>
</ul>


<ngx-spinner bdColor="rgba(0,0,0,0.01)" size="default" color="#57b1b9" type="ball-beat" [fullScreen]="false">
  <p style="color: white"> Loading... </p>
</ngx-spinner>