import { RestablecerComponent } from './@modals/restablecer/restablecer.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MessagingService } from './_services/messaging.service';
import { AuthService } from './_services/auth.service';
import { RegisterComponent } from './@modals/register/register.component';
import { LoginComponent } from './@modals/login/login.component';
import { ApiService } from './_services/api.service';
import { Component, Inject } from '@angular/core';
import { first } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { interval, Subscription } from 'rxjs';
import { DOCUMENT, LocationStrategy } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'taochy';
  images = ['file:///E:/Proyectos/teslasoft/taochy/theme/fashi/img/products/women-4.jpg'];
  categorias: any = [];
  isLoggedIn: any;
  message;
  noLeidas: number = 0;
  noLeidasUS: number = 0;
  subscription: Subscription;
  busquedaForm: FormGroup;


  constructor(private api: ApiService, private router: Router, private modal: NgbModal, private auth: AuthService,
    private messagingService: MessagingService, @Inject(DOCUMENT) document: any, private activatedRoute: ActivatedRoute,
    private deviceService: DeviceDetectorService) {
    this.epicFunction();
    this.busquedaForm = new FormGroup({
      query: new FormControl('', [Validators.required]),
    });
    this.auth.isLoggedIn.subscribe(val => this.isLoggedIn = val);
    this.cargarCategorias();
    this.messagingService.requestPermission()
    this.messagingService.receiveMessage()
    this.message = this.messagingService.currentMessage

    const source = interval(5000);
    this.subscription = source.subscribe(val => {
      this.obtenerNotificaciones();
    });
    if (document.location.href == 'https://latinosventas.ca/') {
      window.location.href = 'https://latinosventas.com';
    }

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });

  }

  deviceInfo;
  isMobile;
  epicFunction() {
    // this.isMobile = this.deviceService.isMobile();
  }

  activarMobiel(isM: boolean) {
    this.isMobile = isM;
  }

  ngOnInit() {

  }

  cargarCategorias() {
    this.api.getResponse('categorias').pipe(first()).subscribe(data => {
      this.categorias = data.filter(p=> p.activa && !p.eliminada);
    }, err => { });
  }

  irCategoria(categoria) {
    this.router.navigate(['Categoria/' + categoria]);
  }

  abrirProductos(id): string {
    return '/Anuncios/' + id;
  }

  login() {
    this.modal.open(LoginComponent, { windowClass: 'modal-holder' });
  }

  register() {
    this.modal.open(RegisterComponent, { windowClass: 'modal-holder' });
  }

  getCorreo(): String {
    return this.auth.getAutenticatedEmail();
  }

  logout() {
    this.auth.logout();
  }

  obtenerNotificaciones() {
    if (this.isLoggedIn) {
      this.api.getResponse('obtenerNotificaciones/' + localStorage.getItem('idUsuario'), true).pipe(first()).subscribe(data => {
        if (data == 0) {
          this.noLeidas = 0;
        }
        else {
          this.noLeidas = data;
        }
      }, err => { });
    }
  }

  obtenerNotificacionesUsuario() {
    this.api.getResponse('obtenerNotificacionesUsuario/' + localStorage.getItem('idUsuario')).pipe(first()).subscribe(data => {
      this.noLeidas = data.length;
    }, err => { });
  }

  publicar() {
    this.router.navigateByUrl('/Publicar');
  }

  buscar() {

    if (this.router.url.includes('/directorio')) {
      this.router.navigateByUrl('/directorio/' + this.busquedaForm.controls['query'].value)
    }
    else {
      this.router.navigateByUrl('/buscar/' + this.busquedaForm.controls['query'].value)
    }

  }

  abrirPerfil() {
    this.router.navigateByUrl('/Perfil');
  }
}
