import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], filter: Object): any {
    console.log('items',items);
    console.log('filter',filter);

    if (!items || !filter) {
        return items;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    var res = items.filter(item => {
      console.log(item.anuncioId.titulo.indexOf(filter));  
      item.anuncioId.titulo.indexOf(filter) !== -1
    });
    var res2 = items.filter(item => {
      console.log('itemss',item.anuncioId.titulo);
      item.anuncioId.titulo.includes(filter)
    });
    console.log('res2',res2);
    return res;
}

}
