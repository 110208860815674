import { environment } from './../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  token: string = 'Bearer ' + localStorage.getItem('token');
  isConnected = false;
  validar: boolean = false;

  constructor(private http: HttpClient) {
  }

  getResponse(url: String, auth?: Boolean, params?: any) {
    this.token = '' + localStorage.getItem('token');
    if (auth) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Authorization': this.token })
      };
      return this.http.get<any>(`${environment.apiUrl}${url}`, httpOptions);
    }
    else {
      return this.http.get<any>(`${environment.apiUrl}${url}`);
    }

  }

  getResponseSingle(url: String, auth?: Boolean, params?: any) {
      return this.http.get<any>(`${url}`);
  }

  postResponse(url: String, auth?: Boolean, params?: any) {
    this.token = '' + localStorage.getItem('token');
    if (auth) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Authorization': this.token })
      };
      return this.http.post<any>(`${environment.apiUrl}${url}`, params, httpOptions);
    }
    else {
      return this.http.post<any>(`${environment.apiUrl}${url}`, params);
    }

  }

  
  putResponse(url: String, auth?: Boolean, params?: any) {
    this.token = '' + localStorage.getItem('token');
    if (auth) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Authorization': this.token })
      };
      return this.http.put<any>(`${environment.apiUrl}${url}`, params, httpOptions);
    }
    else {
      return this.http.put<any>(`${environment.apiUrl}${url}`, params);
    }

  }

  fileResponse(url: String, auth?: Boolean, params?: any) {
    this.token = '' + localStorage.getItem('token');
    if (auth) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': this.token,
          'enctype': 'multipart/form-data'
        })
      };
      return this.http.post<any>(`${environment.apiUrl}${url}`, params, httpOptions);
    }
    else {
      return this.http.post<any>(`${environment.apiUrl}${url}`, params);
    }

  }

  deleteResponse(url: String, auth?: Boolean, params?: any) {
    this.token = '' + localStorage.getItem('token');
    if (auth) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Authorization': this.token })
      };
      return this.http.delete<any>(`${environment.apiUrl}${url}`, httpOptions);
    }
    else {
      return this.http.delete<any>(`${environment.apiUrl}${url}`);
    }


  }

  pdfResponse(url: String, auth?: Boolean, params?: any) {
    this.token = '' + localStorage.getItem('token');
    if (auth) {
      const httpOptions = {
        headers: new HttpHeaders(
          {
            'Authorization': this.token
          }),
        responseType: 'blob' as 'json',
      };
      return this.http.get<any>(`${environment.apiUrl}${url}`, httpOptions);
    }
    else {
      return this.http.get<any>(`${environment.apiUrl}${url}`);
    }
  }
}

