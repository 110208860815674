<div class="modal-header">
  <h4 class="modal-title">Iniciar sesión</h4>
</div>
<div class="modal-body">
  <form [formGroup]="iniciarForm" (ngSubmit)="this.loginWithEmail()">
    <div>
      <input type="text" class="form-control" formControlName="correo" placeholder="Ingresa tu correo" required>
      <div class="alert alert-danger" *ngIf="iniciarForm.get('correo').invalid  && iniciarForm.get('correo').dirty">
        <div *ngIf="iniciarForm.get('correo').errors.required">
          Ingresa un correo electrónico
        </div>
        <div *ngIf="iniciarForm.get('correo').errors.email">
          Ingresa un correo electrónico válido
        </div>
      </div>
    </div>
    <div style="margin-top: 5px;">
      <input type="password" class="form-control" formControlName="password" placeholder="Contraseña" required>
      <div class="alert alert-danger"
        *ngIf="iniciarForm.get('password').invalid  && (iniciarForm.get('password').dirty )">
        <div *ngIf="iniciarForm.get('password').errors.required">
          Ingresa una contraseña
        </div>
      </div>
    </div>

    <div>
      <p>¿Olvidaste tu contraseña? Recupérala <span (click)="recuperar()"
          style="font-weight: bold;color:#00b0b9;cursor: pointer;">aquí</span></p>
    </div>
    <div class="text-center">
      <div class="alert alert-danger" *ngIf="this.message" style="margin-top: 10px;">
        {{this.message}} <span *ngIf="this.resendEmail" (click)="reenviarCorreo()"
          style="cursor: pointer;text-decoration: underline;">¿Reenviar correo de confirmación?</span>
      </div>
      <button type="submit" [disabled]="iniciarForm.invalid" class="btn btn-lt-danger">
        <img src="../../../assets/loadinggif.svg" alt="" width="22" *ngIf="isLoading">
        <span>Iniciar sesión</span>
      </button>

    </div>

  </form>
  <div class="text-center">
    <hr>
  </div>
  <a class="btn btn-block btn-social btn-facebook" style="color:#fff !important" (click)="facebookLogin()">
    <span class="fa fa-facebook"></span>
    Ingresar con Facebook
  </a>
  <a class="btn btn-block btn-social btn-facebook" style="color:gray !important;background-color: #f9f9f9;"
    (click)="googleLogin()">
    <span class="fa fa-google" style="color: red;"></span>
    Ingresar con Google
  </a>
  <a class="btn btn-block btn-social btn-facebook" style="color:#fff !important; background-color: rgb(29, 161, 242);"
    (click)="twitterLogin()">
    <span class="fa fa-twitter"></span>
    Ingresar con Twitter
  </a>

</div>